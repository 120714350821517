import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  offers: [],
  filteredOffers: [],
  selectedOffer: {
    currency: 'EUR',
    items: [
      {
        item: '',
        unit: 'service',
        quantity: 1,
        price: ''
      }
    ],
    discount: {
      type: 'percentage',
      value: 0
    }
  },
  searchTerm: '',
  checkboxValues: []
}

const stateSlice = createSlice({
  name: 'offers/state',
  initialState: INITIAL_STATE,
  reducers: {
    setOffers: (state, action) => {
      state.offers = action.payload
    },
    setFilteredOffers: (state, action) => {
      state.filteredOffers = action.payload
    },
    setSelectedOffer: (state, action) => {
      state.selectedOffer = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setCheckboxValues: (state, action) => {
      state.checkboxValues = action.payload
    }
  }
})

export const {
  setOffers,
  setFilteredOffers,
  setSelectedOffer,
  setSearchTerm,
  setCheckboxValues
} = stateSlice.actions

export default stateSlice.reducer
