import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-http-backend'

i18n
  .use(initReactI18next)
  .use(backend)
  .init({
    fallbackLng: 'en',
    lng: window.localStorage.getItem('lang') || 'en',
    defaultNS: 'general',
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: `${import.meta.env.VITE_API_PREFIX}/locales/{{lng}}/{{ns}}.json`,
      addPath: `${import.meta.env.VITE_API_PREFIX}/locales/{{lng}}/{{ns}}`
    },
    supportedLngs: ['en', 'sk', 'cs', 'de'],
    saveMissing: import.meta.env.MODE !== 'development'
  })

export const dateLocales = {
  en: () => {
    return import('dayjs/locale/en')
  },
  sk: () => {
    return import('dayjs/locale/sk')
  },
  cs: () => {
    return import('dayjs/locale/cs')
  },
  de: () => {
    return import('dayjs/locale/de')
  }
}

export default i18n
