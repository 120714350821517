import React, { useState, useContext, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Dialog, FormItem, Input } from '@/components/ui'
import FormDescription from '@/components/shared/ui/FormDescription'
import { setUser } from '@/store/auth/userSlice'
import { useTranslation } from 'react-i18next'
import FeedbackContext from '@/providers/feedback.context'
import { CustomLoader } from './CustomLoading'

const FeedbackDialog = () => {
  const { isModalOpen, closeModal, submitFeedback } =
    useContext(FeedbackContext)
  const { t } = useTranslation('feedback', { useSuspense: false })

  const dispatch = useDispatch()

  const [content, setContent] = useState('')

  const handleSubmit = async (isSubmitAction = false) => {
    if (!content && isSubmitAction) return

    await submitFeedback(content)

    dispatch(setUser({ submittedWeeklyFeedback: true }))

    closeModal()
  }

  return (
    <Dialog
      isOpen={isModalOpen}
      onClose={() => handleSubmit(false)}
      onRequestClose={() => handleSubmit(false)}
    >
      <Suspense
        fallback={
          <div className="flex h-screen items-center justify-center">
            <CustomLoader size="xl" />
          </div>
        }
      >
        <FormDescription
          title={t('feedback-modal.title', 'Weekly Nexile App Check-in')}
          desc={t(
            'feedback-modal.desc',
            'How was your Nexile App experience this week? Share your thoughts to help us improve, or simply close this if you prefer to skip.'
          )}
        />

        <div className="mt-4">
          <FormItem
            asterisk
            label={t('feedback-modal.inputs.feedback.label', 'Feedback')}
          >
            <Input
              placeholder={t(
                'feedback-modal.inputs.feedback.placeholder',
                'Type your feedback here...'
              )}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              textArea
            />
          </FormItem>
        </div>

        <div>
          <Button
            className="w-full"
            variant="solid"
            onClick={() => handleSubmit(true)}
          >
            {t('feedback-modal.buttons.submit', 'Submit')}
          </Button>
        </div>
      </Suspense>
    </Dialog>
  )
}

export default FeedbackDialog
