import Notification from '@/components/ui/Notification'

const OpenToast = (type, title, message) => {
  return (
    <Notification type={type} title={title} closable>
      {message}
    </Notification>
  )
}

export default OpenToast
