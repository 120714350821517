import React, { forwardRef, Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { STEPS_STATUS } from '../utils/constant'

const { COMPLETE, PENDING, IN_PROGRESS, ERROR } = STEPS_STATUS

const Steps = forwardRef((props, ref) => {
  const {
    className,
    children,
    vertical = false,
    current = 0,
    status = IN_PROGRESS,
    onChange,
    ...rest
  } = props

  const count = Children.count(children)

  const items = Children.map(children, (item, index) => {
    const itemStyles = {
      flexBasis: index < count - 1 ? `${100 / (count - 1)}%` : undefined,
      maxWidth: index === count - 1 ? `${100 / count}%` : undefined
    }

    const itemValue = item.props.value || index
    const nextItemValue = children[index + 1]?.props.value || index + 1

    let percentage = 0
    if (index < count - 1) {
      if (itemValue < current && nextItemValue <= current) {
        percentage = 100
      } else if (itemValue < current && nextItemValue > current) {
        percentage = ((current - itemValue) / (nextItemValue - itemValue)) * 100
      }
    }

    const itemProps = {
      stepNumber: index + 1,
      status: PENDING,
      style: !vertical ? itemStyles : undefined,
      isLast: index === count - 1,
      vertical,
      onStepChange: onChange ? () => onChange(index) : undefined,
      percentage,
      ...item.props
    }

    if (status === ERROR && index === current - 1) {
      itemProps.className = classNames('steps-item-error')
    }

    if (!item.props.status && itemValue <= current) {
      itemProps.status = COMPLETE
    }

    return cloneElement(item, itemProps)
  })

  return (
    <div
      ref={ref}
      className={classNames('steps', vertical && 'steps-vertical', className)}
      {...rest}
    >
      {items}
    </div>
  )
})

Steps.displayName = 'Steps'

Steps.propTypes = {
  vertical: PropTypes.bool,
  current: PropTypes.number,
  status: PropTypes.oneOf([COMPLETE, PENDING, IN_PROGRESS, ERROR])
}

export default Steps
