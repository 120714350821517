import React, { useState, useEffect, useRef } from 'react'
import useMergedRef from '../hooks/useMergeRef'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { sha256 } from 'js-sha256'
import Badge from '../Badge'

const getInitials = (name) => {
  const [firstName, lastName] = name.split(' ')

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
  }

  return firstName.charAt(0).toUpperCase()
}

const getUserColor = (initials) => {
  const hash = sha256(initials)

  let color = ''
  for (let i = 0; i < 3; i++) {
    color += hash.substr(i * 2, 2)
  }

  return `#${color}`
}

const Avatar = React.forwardRef((props, ref) => {
  const {
    size = 'md',
    src,
    srcSet,
    shape = 'rounded',
    alt,
    className,
    name,
    icon,
    textClassName,
    status,
    hideWhenOffline = false,
    ...rest
  } = props

  let { children } = props
  const [scale, setScale] = useState(1)

  const avatarChildren = useRef()
  const avatarNode = useRef()

  const avatarMergeRef = useMergedRef(ref, avatarNode)

  const innerScale = () => {
    if (!avatarChildren.current || !avatarNode.current) {
      return
    }
    const avatarChildrenWidth = avatarChildren.current.offsetWidth
    const avatarNodeWidth = avatarNode.current.offsetWidth
    if (avatarChildrenWidth === 0 || avatarNodeWidth === 0) {
      return
    }
    setScale(
      avatarNodeWidth - 8 < avatarChildrenWidth
        ? (avatarNodeWidth - 8) / avatarChildrenWidth
        : 1
    )
  }

  useEffect(() => {
    innerScale()
  }, [scale, children])

  const sizeStyle =
    typeof size === 'number'
      ? {
          width: size,
          height: size,
          minWidth: size,
          lineHeight: `${size}px`,
          fontSize: icon ? size / 2 : 12
        }
      : {}

  const classes = classNames(
    'avatar',
    `avatar-${shape}`,
    typeof size === 'string' ? `avatar-${size}` : '',
    className
  )

  if (src) {
    children = (
      <img
        className={`avatar-img avatar-${shape}`}
        src={src}
        srcSet={srcSet}
        alt={alt}
        loading="lazy"
      />
    )
  } else if (icon) {
    children = (
      <span className={classNames('avatar-icon', `avatar-icon-${size}`)}>
        {icon}
      </span>
    )
  } else if (name) {
    const initials = getInitials(name)

    children = (
      <div
        aria-label={name}
        role="img"
        style={{
          width: size,
          height: size,
          backgroundColor: getUserColor(initials),
          borderRadius: shape === 'circle' ? '50%' : '0.25rem'
        }}
      >
        <div
          style={{
            color: '#fff',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontStyle: 'inherit',
            fontWeight: 'inherit'
          }}
          className={textClassName}
        >
          {initials}
        </div>
      </div>
    )
  } else {
    const childrenSizeStyle =
      typeof size === 'number' ? { lineHeight: `${size}px` } : {}
    const stringCentralized = {
      transform: `translateX(-50%) scale(${scale})`
    }
    children = (
      <span
        className={`avatar-string ${
          typeof size === 'number' ? '' : `avatar-inner-${size}`
        }`}
        style={{
          ...childrenSizeStyle,
          ...stringCentralized,
          ...(typeof size === 'number' ? { height: size } : {})
        }}
        ref={(node) => {
          avatarChildren.current = node
        }}
      >
        {children}
      </span>
    )
  }

  return (
    <span
      className={classes}
      style={{ ...sizeStyle, ...rest.style }}
      ref={avatarMergeRef}
      {...rest}
    >
      {status ? (
        <Badge
          innerClass={classNames(
            'mr-1 mt-7 w-3 h-3 border border-white',
            status === 'ONLINE' && 'bg-emerald-500',
            status === 'AWAY' && 'bg-yellow-500',
            status === 'OFFLINE' && 'bg-gray-500',
            status === 'DO_NOT_DISTURB' && 'bg-red-500',
            status === 'OFFLINE' && hideWhenOffline && 'opacity-0'
          )}
        >
          {children}
        </Badge>
      ) : (
        children
      )}
    </span>
  )
})

Avatar.propTypes = {
  shape: PropTypes.oneOf(['rounded', 'square', 'circle']),
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['sm', 'md', 'lg']),
    PropTypes.number
  ]),
  icon: PropTypes.node,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.oneOf(['ONLINE', 'AWAY', 'DO_NOT_DISTURB', 'OFFLINE'])
}

export default Avatar
