import pathToRegex from 'path-to-regex'

const findMatchingRoute = (routes, currentPath) => {
  for (const route of routes) {
    const parser = new pathToRegex(route.path)
    const result = parser.match(currentPath)

    if (result) {
      return { ...route, params: result.params }
    }
  }

  return null
}

export default findMatchingRoute
