import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'employees/state',
  initialState: {
    selectedGroup: null,
    createEmployeeGroupModalOpen: false,
    editEmployeeGroupModalOpen: false,
    addEmployeesToGroupModal: {
      open: false,
      type: null,
      selectedEmployees: []
    },
    viewEmployeeDrawerOpen: false,
    employeeId: null,
    page: 1,
    totalEmployees: 0,
    hasMore: true,
    searchTerm: '',
    activeComponent: 'GROUPS',
    preferredView: 'TABLE',
    filter: {
      statuses: []
    }
  },
  reducers: {
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload
    },
    setCreateEmployeeGroupModalOpen: (state, action) => {
      state.createEmployeeGroupModalOpen = action.payload
    },
    setEditEmployeeGroupModalOpen: (state, action) => {
      state.editEmployeeGroupModalOpen = action.payload
    },
    setAddEmployeesToGroupModal: (state, action) => {
      state.addEmployeesToGroupModal = action.payload
    },
    setViewEmployeeDrawerOpen: (state, action) => {
      state.viewEmployeeDrawerOpen = action.payload
    },
    setEmployeeId: (state, action) => {
      state.employeeId = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setTotalEmployees: (state, action) => {
      state.totalEmployees = action.payload
    },
    incrementTotalEmployees: (state, action) => {
      state.totalEmployees += action.payload
    },
    decrementTotalEmployees: (state, action) => {
      if (action.payload > 0) {
        state.totalEmployees -= action.payload
      } else {
        state.totalEmployees--
      }
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setActiveComponent: (state, action) => {
      state.activeComponent = action.payload
    },
    setPreferredView: (state, action) => {
      state.preferredView = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    }
  }
})

export const {
  setSelectedGroup,
  setCreateEmployeeGroupModalOpen,
  setEditEmployeeGroupModalOpen,
  setAddEmployeesToGroupModal,
  setViewEmployeeDrawerOpen,
  setEmployeeId,
  setPage,
  setTotalEmployees,
  incrementTotalEmployees,
  decrementTotalEmployees,
  setHasMore,
  setSearchTerm,
  setActiveComponent,
  setPreferredView,
  setFilter
} = stateSlice.actions

export default stateSlice.reducer
