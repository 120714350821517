import React from 'react'

const authRoute = [
  {
    title: 'Sign In',
    key: 'signIn',
    path: `/sign-in`,
    component: React.lazy(() => import('@/views/auth/SignIn')),
    authority: []
  },
  {
    title: 'Sign Up',
    key: 'signUp',
    path: `/sign-up`,
    component: React.lazy(() => import('@/views/auth/SignUp')),
    authority: []
  },
  {
    title: 'Forgot Password',
    key: 'forgotPassword',
    path: `/forgot-password`,
    component: React.lazy(() => import('@/views/auth/ForgotPassword')),
    authority: []
  },
  {
    title: 'Reset Password',
    key: 'resetPassword',
    path: `/reset-password`,
    component: React.lazy(() => import('@/views/auth/ResetPassword')),
    authority: []
  },
  {
    title: 'Verify Email',
    key: 'verifyEmail',
    path: `/verify-email`,
    component: React.lazy(() => import('@/views/auth/VerifyEmail')),
    authority: []
  }
]

export default authRoute
