import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'auth/session',
  initialState: {
    tokens: {},
    signedIn: false
  },
  reducers: {
    onSignInSuccess: (state, action) => {
      state.signedIn = true
      state.tokens = action.payload
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false
      state.tokens = {}
    }
  }
})

export const { onSignInSuccess, onSignOutSuccess } = sessionSlice.actions

export default sessionSlice.reducer
