import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'customers/data',
  initialState: {
    loading: false,
    customers: [],
    groups: []
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCustomers: (state, action) => {
      state.customers = action.payload
    },
    appendCustomers: (state, action) => {
      state.customers = [...state.customers, ...action.payload]
    },
    setCustomerGroups: (state, action) => {
      state.groups = action.payload
    },
    updateGroup: (state, action) => {
      const group = state.groups.find((group) => group.id === action.payload.id)

      if (group) {
        Object.assign(group, action.payload)
      }
    },
    removeCustomersFromGroups: (state, action) => {
      for (const id of action.payload) {
        const customerIndex = state.customers.findIndex((e) => e.id === id)

        if (customerIndex === -1) return

        for (const group of state.customers[customerIndex].groups) {
          const groupIndex = state.groups.findIndex((g) => g.id === group.id)

          if (groupIndex !== -1) {
            state.groups[groupIndex].customers.filter((c) => c.id !== id)
          }
        }

        state.customers.splice(customerIndex, 1)
      }
    },
    removeCustomersFromGroup: (state, action) => {
      const groupIndex = state.groups.findIndex(
        (g) => g.id === action.payload.group
      )

      for (const id of action.payload.ids) {
        const customerIndex = state.customers.findIndex(
          (e) =>
            e.id === id && e.groups.some((g) => g.id === action.payload.group)
        )

        if (customerIndex === -1) return

        if (groupIndex !== -1) {
          state.groups[groupIndex].customers.filter((c) => c.id !== id)
        }

        state.customers.splice(customerIndex, 1)
      }
    },
    deleteCustomer: (state, action) => {
      const customerIndex = state.customers.findIndex(
        (e) => e.id === action.payload
      )

      if (customerIndex === -1) return

      const customerGroups = state.customers[customerIndex]?.groups

      if (customerGroups && Array.isArray(customerGroups)) {
        for (const group of customerGroups) {
          const groupIndex = state.groups.findIndex((g) => g.id === group.id)

          if (groupIndex !== -1) {
            state.groups[groupIndex].customers.filter(
              (c) => c.id !== action.payload
            )
          }
        }
      }

      state.customers.splice(customerIndex, 1)
    }
  }
})

export const {
  setCustomers,
  setCustomerGroups,
  setLoading,
  appendCustomers,
  updateGroup,
  removeCustomersFromGroups,
  removeCustomersFromGroup,
  deleteCustomer
} = dataSlice.actions

export default dataSlice.reducer
