import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import FeedbackContext from './feedback.context'
import { apiSubmitFeedback } from '@/services/FeedbackService'
import FeedbackDialog from '@/components/shared/utils/FeedbackDialog'

export const FeedbackProvider = ({ children }) => {
  const user = useSelector((state) => state.auth.user)
  const userId = useSelector((state) => state?.auth?.user?.id)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const submitFeedback = async (content) => {
    try {
      const resp = await apiSubmitFeedback(content)

      if (resp.status === 201) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  useEffect(() => {
    if (user && !user.submittedWeeklyFeedback && userId) {
      setIsModalOpen(true)
    }
  }, [user, userId])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <FeedbackContext.Provider
      value={{
        isModalOpen,
        closeModal,
        submitFeedback
      }}
    >
      {children}
      <FeedbackDialog />
    </FeedbackContext.Provider>
  )
}
