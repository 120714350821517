import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  id: null,
  name: '',
  email: '',
  lang: 'en',
  role: '',
  avatar: '',
  workspaces: [],
  selectedWorkspace: null,
  notifications: 0
}

export const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload }
    },
    userLoggedOut: () => initialState
  }
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
