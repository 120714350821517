import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'customers/state',
  initialState: {
    selectedCustomer: null,
    selectedGroup: null,
    addCustomersToGroupModal: {
      open: false,
      type: null,
      selectedCustomers: []
    },
    editGroupModalOpen: false,
    createGroupModalOpen: false,
    customerModalOpen: false,
    customer: null,
    viewDrawerOpen: false,
    activeComponent: 'GROUPS',
    page: 1,
    totalCustomers: 0,
    hasMore: true,
    searchTerm: '',
    preferredCustomersView: 'TABLE'
  },
  reducers: {
    setActiveComponent: (state, action) => {
      state.activeComponent = action.payload
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload
    },
    setAddCustomersToGroupModal: (state, action) => {
      state.addCustomersToGroupModal = action.payload
    },
    setEditGroupModalOpen: (state, action) => {
      state.editGroupModalOpen = action.payload
    },
    setCreateGroupModalOpen: (state, action) => {
      state.createGroupModalOpen = action.payload
    },
    setCustomerModalOpen: (state, action) => {
      state.customerModalOpen = action.payload
    },
    setCustomer: (state, action) => {
      state.customer = action.payload
    },
    setViewDrawerOpen: (state, action) => {
      state.viewDrawerOpen = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setTotalCustomers: (state, action) => {
      state.totalCustomers = action.payload
    },
    incrementTotalCustomers: (state, action) => {
      state.totalCustomers += action.payload
    },
    decrementTotalCustomers: (state, action) => {
      if (action.payload > 0) {
        state.totalCustomers -= action.payload
      } else {
        state.totalCustomers--
      }
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setPreferredCustomersView: (state, action) => {
      state.preferredCustomersView = action.payload
    }
  }
})

export const {
  setSelectedCustomer,
  setSelectedGroup,
  setAddCustomersToGroupModal,
  setEditGroupModalOpen,
  setCreateGroupModalOpen,
  setCustomerModalOpen,
  setCustomer,
  setViewDrawerOpen,
  setActiveComponent,
  setPage,
  setTotalCustomers,
  incrementTotalCustomers,
  decrementTotalCustomers,
  setHasMore,
  setSearchTerm,
  setPreferredCustomersView
} = stateSlice.actions

export default stateSlice.reducer
