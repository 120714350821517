import React, { useState, useCallback, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Popper, Reference, Manager } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import Arrow from './Arrow'
import PropTypes from 'prop-types'
import { Portal } from 'react-portal'

const PopperElement = (props) => {
  const { title, titleClassName, forceUpdate, open, icon, description } = props

  useEffect(() => {
    if (open) {
      forceUpdate()
    }
  }, [open])

  return (
    <div className={classNames('flex flex-col', description && 'py-2')}>
      <div className="flex flex-row gap-2 items-center">
        {icon && <span>{icon}</span>}
        <span className={classNames('font-semibold', titleClassName)}>
          {title}
        </span>
      </div>

      {description && (
        <div className={classNames('mt-2', icon && 'px-3')}>
          <span>{description}</span>
        </div>
      )}
    </div>
  )
}

const Tooltip = (props) => {
  const {
    className,
    children,
    title,
    titleClassName,
    description,
    icon,
    showArrow = false,
    placement = 'top',
    wrapperClass,
    isOpen = false,
    ...rest
  } = props

  const [tooltipOpen, setTooltipOpen] = useState(isOpen)
  const tooltipNode = useRef()

  const tooltipBackground = 'gray-800'
  const dropShadowStyle = '0 4px 6px rgba(0, 0, 0, 0.1)'

  const defaultTooltipClass = classNames(
    'tooltip bg-white border text-gray-800',
    className
  )

  const toggleTooltip = useCallback(
    (bool) => {
      if (!isOpen) {
        setTooltipOpen(bool)
      }
    },
    [isOpen]
  )

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <span
            className={classNames('tooltip-wrapper', wrapperClass)}
            ref={ref}
            onMouseEnter={() => toggleTooltip(true)}
            onMouseLeave={() => toggleTooltip(false)}
          >
            {children}
          </span>
        )}
      </Reference>
      {tooltipOpen && !rest.hidden && (
        <Portal>
          <Popper
            placement={placement}
            innerRef={(node) => (tooltipNode.current = node)}
            modifiers={[
              { name: 'arrow', options: { element: Arrow } },
              { name: 'offset', options: { offset: [0, 7] } }
            ]}
            strategy={'fixed'}
          >
            {({ ref, style, ...popperProps }) => (
              <AnimatePresence>
                <motion.div
                  className={defaultTooltipClass}
                  ref={ref}
                  style={{ ...style, boxShadow: dropShadowStyle }}
                  initial={{
                    opacity: 0,
                    visibility: 'hidden'
                  }}
                  animate={
                    tooltipOpen
                      ? {
                          opacity: 1,
                          visibility: 'visible'
                        }
                      : {
                          opacity: 0,
                          visibility: 'hidden'
                        }
                  }
                  transition={{
                    duration: 0.15,
                    type: 'tween'
                  }}
                >
                  <PopperElement
                    open={tooltipOpen}
                    title={title}
                    icon={icon}
                    description={description}
                    titleClassName={titleClassName}
                    {...rest}
                    {...popperProps}
                  />
                  {showArrow && (
                    <Arrow placement={placement} color={tooltipBackground} />
                  )}
                </motion.div>
              </AnimatePresence>
            )}
          </Popper>
        </Portal>
      )}
    </Manager>
  )
}

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end'
  ]),
  wrapperClass: PropTypes.string,
  isOpen: PropTypes.bool
}

export default Tooltip
