import React, { useState, useEffect, useRef } from 'react'
import OnboardingContext from './onboarding.context'
import { useNavigate, useLocation } from 'react-router-dom'
import Joyride, { STATUS, ACTIONS, EVENTS } from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { getOnboardingSteps } from '@/constants/onboarding.constant'
import { useDispatch, useSelector } from 'react-redux'
import { apiUpdateUser } from '@/services/UserService'
import { toast } from '@/components/ui'
import OpenToast from '@/utils/openToast'
import { setUser } from '@/store/auth/userSlice'

export const OnboardingProvider = ({ children }) => {
  const { t } = useTranslation('general')

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const observerRef = useRef(null)

  const {
    id: userId,
    finishedOnboarding,
    finishedProjectOnboarding
  } = useSelector((state) => state.auth.user)

  const [runTour, setRunTour] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const [steps, setSteps] = useState([])
  const [type, setType] = useState('')

  const pathMatches = (path) => {
    const pathParts = path.split('/')
    const locationParts = (location.pathname + location.hash).split('/')

    if (pathParts.length !== locationParts.length) {
      return false
    }

    for (let i = 0; i < pathParts.length; i++) {
      const pathPart = pathParts[i]
      const locationPart = locationParts[i]

      if (pathPart === locationPart) {
        continue
      } else if (pathPart.startsWith(':')) {
        continue
      } else {
        return false
      }
    }

    return true
  }

  const generatePath = (currentPath, path) => {
    const pathParts = path.split('/')
    const currentPathParts = currentPath.split('/')

    let newPath = ''

    for (let i = 0; i < pathParts.length; i++) {
      const pathPart = pathParts[i]

      if (pathPart.startsWith(':')) {
        for (let j = i; j < currentPathParts.length; j++) {
          const currentPathPart = currentPathParts[j]

          if (currentPathPart.startsWith(pathPart.split('#')[0])) {
            const locationParts = location.pathname.split('/')

            if (pathPart.includes('#')) {
              const hash = pathPart.split('#')[1]
              newPath += `/${locationParts[j]}#${hash}`
            } else {
              newPath += `/${locationParts[j]}`
            }
          }
        }
      } else if (pathPart) {
        newPath += `/${pathPart}`
      }
    }

    return newPath
  }

  const waitForElement = (selector) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let elementFound = false
      const timeout = setTimeout(() => {
        resolve(null)
      }, 10000)

      const checkElement = () => {
        const element = document.querySelector(selector)
        if (element) {
          elementFound = true
          clearTimeout(timeout)
          if (observerRef.current) {
            observerRef.current.disconnect()
          }
          resolve(element)
        }
      }

      // eslint-disable-next-line no-unmodified-loop-condition
      while (!elementFound) {
        checkElement()

        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, 100))
      }
    })
  }

  const finishOnboarding = async () => {
    const finishedOnboardingStatus =
      type === 'INITIAL' ? true : finishedOnboarding
    const finishedProjectOnboardingStatus =
      type === 'PROJECT' ? true : finishedProjectOnboarding

    await apiUpdateUser(userId, {
      finishedOnboarding: finishedOnboardingStatus,
      finishedProjectOnboarding: finishedProjectOnboardingStatus
    })

    toast.push(
      OpenToast(
        'success',
        t('alerts.success', 'Success'),
        t(
          'onboarding.success',
          `You're all set to go! If you ever need assistance, our support team is just a click away.`
        )
      )
    )

    dispatch(
      setUser({
        finishedOnboarding: finishedOnboardingStatus,
        finishedProjectOnboarding: finishedProjectOnboardingStatus
      })
    )
  }

  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type, step } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED].includes(status) &&
      [ACTIONS.STOP, ACTIONS.SKIP].includes(action)
    ) {
      setRunTour(false)
      localStorage.removeItem('onboardingStepIndex')

      if (status !== STATUS.PAUSED) {
        await finishOnboarding()
      }
    } else if (
      (action === ACTIONS.NEXT || action === ACTIONS.PREV) &&
      type === EVENTS.STEP_AFTER
    ) {
      const nextIndex = action === ACTIONS.NEXT ? index + 1 : index - 1
      localStorage.setItem('onboardingStepIndex', nextIndex.toString())

      if (nextIndex >= 0 && nextIndex < steps.length) {
        if (
          step.data &&
          ((step.data.next && action === ACTIONS.NEXT) ||
            (step.data.prev && action === ACTIONS.PREV))
        ) {
          setRunTour(false)

          if (action === ACTIONS.NEXT) {
            navigate(generatePath(step.path, step.data.next))
          } else {
            navigate(generatePath(step.path, step.data.prev))
          }
        } else {
          await waitForElement(steps[nextIndex].target)

          setStepIndex(nextIndex)
        }
      } else {
        setRunTour(false)
        localStorage.removeItem('onboardingStepIndex')

        await finishOnboarding()
      }
    }
  }

  const startOnboarding = async () => {
    let stepIndex = 0

    const storedStepIndex = localStorage.getItem('onboardingStepIndex')
    if (storedStepIndex) {
      stepIndex = parseInt(storedStepIndex)
    }

    if (pathMatches(steps[stepIndex].path)) {
      await waitForElement(steps[stepIndex].target)

      setStepIndex(stepIndex)
      setRunTour(true)
    }
  }

  useEffect(() => {
    if (location.pathname.startsWith('/projects/')) {
      setType('PROJECT')
    } else {
      setType('INITIAL')
    }
  }, [location])

  useEffect(() => {
    setSteps(getOnboardingSteps(t, type))
  }, [type])

  useEffect(() => {
    if (
      steps.length > 0 &&
      !runTour &&
      ((type === 'INITIAL' && !finishedOnboarding) ||
        (type === 'PROJECT' && !finishedProjectOnboarding))
    ) {
      startOnboarding()
    }
  }, [type, steps, location])

  return (
    <OnboardingContext.Provider value={{ startOnboarding, type, setType }}>
      <Joyride
        steps={steps}
        run={runTour}
        stepIndex={stepIndex}
        continuous={true}
        showSkipButton={true}
        showProgress={false}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableScrollParentFix={true}
        locale={{
          back: t('onboarding.buttons.back', 'Back'),
          last: t('onboarding.buttons.finish', 'Finish'),
          next: t('onboarding.buttons.next', 'Next'),
          skip: t('onboarding.buttons.skip', 'Skip')
        }}
        styles={{
          options: {
            arrowColor: '#ffffff',
            backgroundColor: '#ffffff',
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#455bcb',
            textColor: '#5d5d5d',
            zIndex: 1000,
            spotlightShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          },
          tooltip: {
            fontSize: 15,
            padding: 20,
            borderRadius: '.5rem'
          },
          tooltipContainer: {
            textAlign: 'left'
          },
          tooltipContent: {
            padding: '5px 5px'
          },
          tooltipTitle: {
            fontSize: 18,
            fontWeight: 'bold',
            marginBottom: 10
          },
          buttonNext: {
            backgroundColor: '#455bcb',
            fontSize: 14,
            padding: '10px 20px',
            borderRadius: '.375rem',
            fontWeight: 'bold'
          },
          buttonBack: {
            backgroundColor: '#455bcb',
            fontSize: 14,
            padding: '10px 20px',
            borderRadius: '.375rem',
            fontWeight: 'bold',
            marginRight: 10,
            color: '#ffffff'
          },
          buttonSkip: {
            color: '#5d5d5d',
            border: '1px solid #CECECE',
            borderRadius: '.375rem',
            padding: '10px 20px'
          },
          buttonClose: {
            display: 'none'
          },
          floaterStyles: {
            floater: {
              filter: 'unset'
            }
          }
        }}
      />
      {children}
    </OnboardingContext.Provider>
  )
}

export default OnboardingProvider
