import ky from 'ky'
import appConfig from '@/configs/app.config'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]

const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) return parts.pop().split(';').shift()
}

const BaseService = ky.extend({
  credentials: 'include',
  timeout: 60000,
  prefixUrl: appConfig.apiPrefix,
  json: true,
  throwHttpErrors: false,
  retry: {
    limit: 0
  },
  hooks: {
    beforeRequest: [
      async (request) => {
        const { locale, workspace } = store.getState()

        const accessToken = getCookie('_nex_at')

        if (!accessToken) {
          const refreshToken = getCookie('_nex_rt')

          if (refreshToken) {
            const resp = await ky.post(
              `${appConfig.apiPrefix}/auth/refresh-tokens`,
              {
                credentials: 'include',
                throwHttpErrors: false,
                retry: {
                  limit: 0
                }
              }
            )

            if (resp.status !== 200) {
              store.dispatch(onSignOutSuccess())
            }
          }
        }

        if (locale.currentLang) {
          request.headers.set('x-lang', locale.currentLang)
        } else {
          const lang = window.localStorage.getItem('lang') || 'en'

          request.headers.set('x-lang', lang)
        }

        if (workspace && workspace.data.id) {
          request.headers.set('x-workspace', workspace.data.id)
        }

        return request
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response) {
          if (unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
          }

          try {
            const body = await response.json()

            // eslint-disable-next-line require-atomic-updates
            response.data = body
          } catch (error) {
            // eslint-disable-next-line require-atomic-updates
            response.data = null
          }
        }

        return response
      }
    ]
  }
})

export default BaseService
