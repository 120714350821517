import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  apiGetUserWorkspaces,
  apiCreateWorkspace,
  apiUpdateWorkspace,
  apiGetWorkspace,
  apiGetInvite,
  apiInviteMembers,
  apiRemoveMember,
  apiUpdateMember,
  apiRestoreMember,
  apiAcceptInvite,
  apiRejectInvite,
  apiGetLabels,
  apiCreateLabel,
  apiUpdateLabel,
  apiDeleteLabel
} from '@/services/WorkspaceService'
import { setSelectedWorkspace, setWorkspaces } from '@/store/workspaces'
import { initialState, setWorkspace } from '@/store/workspace'
import { useLocation, useNavigate } from 'react-router-dom'
import { protectedRoutes } from '@/configs/routes.config'
import findMatchingRoute from '@/utils/findMatchingRoute'
import WorkspaceContext from './workspace.context'

const WorkspaceProvider = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { loaded, workspaces } = useSelector((state) => state.workspaces)
  const { tokens, signedIn } = useSelector((state) => state.auth.session)

  useEffect(() => {
    if (workspaces?.length === 0 && loaded) {
      const matchingRoute = findMatchingRoute(
        protectedRoutes,
        location.pathname
      )
      const requiresWorkspace = matchingRoute?.meta?.requiresWorkspace

      if (requiresWorkspace) {
        navigate('/setup')
      }
    }
  }, [workspaces, loaded, navigate, location.pathname])

  const GetWorkspaces = async () => {
    const response = await apiGetUserWorkspaces()

    dispatch(setWorkspaces(response.data))

    if (response?.data?.length > 0) {
      const selectedWorkspace = window.localStorage.getItem('selectedWorkspace')

      if (
        selectedWorkspace &&
        response?.data?.find((x) => x.id === selectedWorkspace)
      ) {
        // eslint-disable-next-line no-use-before-define
        await SelectWorkspace(selectedWorkspace, response.data)
      } else {
        // eslint-disable-next-line no-use-before-define
        await SelectWorkspace(response.data[0].id, response.data)
      }
    }
  }

  const InviteMembers = async (members) => {
    try {
      const resp = await apiInviteMembers(members)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const GetInvite = async (token) => {
    try {
      const resp = await apiGetInvite(token)

      if (resp.status === 200) {
        return resp?.data
      }

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const AcceptInvite = async (token) => {
    try {
      const resp = await apiAcceptInvite(token)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const RejectInvite = async (token) => {
    try {
      const resp = await apiRejectInvite(token)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const CreateWorkspace = async (values) => {
    try {
      const resp = await apiCreateWorkspace(values)

      if (resp.status === 201) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      console.log('error', error)
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const UpdateWorkspace = async (values) => {
    try {
      const resp = await apiUpdateWorkspace(values)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const SelectWorkspace = async (id, wrkspaces = []) => {
    try {
      const resp = await apiGetWorkspace(id)

      if (resp.status === 200) {
        if (resp?.data?.id) {
          dispatch(
            setWorkspace({
              ...resp?.data,
              loaded: true
            })
          )
        } else {
          dispatch(
            setWorkspace({
              ...initialState,
              loaded: true
            })
          )
        }

        dispatch(setWorkspaces(wrkspaces.length > 0 ? wrkspaces : workspaces))
        dispatch(setSelectedWorkspace(id))

        window.localStorage.setItem('selectedWorkspace', id)

        return {
          status: 'success',
          message: ''
        }
      }

      dispatch(
        setWorkspace({
          ...initialState,
          loaded: true
        })
      )

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      dispatch(
        setWorkspace({
          ...initialState,
          loaded: true
        })
      )

      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const UpdateMember = async (id, values) => {
    try {
      const resp = await apiUpdateMember(id, values)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const RemoveMember = async (id) => {
    try {
      const resp = await apiRemoveMember(id)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const RestoreMember = async (id) => {
    try {
      const resp = await apiRestoreMember(id)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }

      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const getLabels = async (type) => {
    try {
      const resp = await apiGetLabels(type)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const createLabel = async (data) => {
    try {
      const resp = await apiCreateLabel(data)

      if (resp.status === 201) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const updateLabel = async (labelId, data) => {
    try {
      const resp = await apiUpdateLabel(labelId, data)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const deleteLabel = async (labelId) => {
    try {
      const resp = await apiDeleteLabel(labelId)

      if (resp.status === 200) {
        return {
          status: 'success',
          message: '',
          data: resp?.data
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  useEffect(() => {
    if (tokens && signedIn) {
      GetWorkspaces()
    }
  }, [tokens, signedIn])

  return (
    <WorkspaceContext.Provider
      value={{
        InviteMembers,
        GetInvite,
        AcceptInvite,
        RejectInvite,
        CreateWorkspace,
        UpdateWorkspace,
        SelectWorkspace,
        GetWorkspaces,
        UpdateMember,
        RemoveMember,
        RestoreMember,
        getLabels,
        createLabel,
        updateLabel,
        deleteLabel
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}

export { WorkspaceContext, WorkspaceProvider }
