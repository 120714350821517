import React, { useRef, useCallback, useState } from 'react'
import DropdownMenu from './DropdownMenu'
import DropdownToggle from './DropdownToggle'
import PropTypes from 'prop-types'
import useUniqueId from '../hooks/useUniqueId'
import DropdownContext from './context/dropdownContext'
import DropdownMenuContext, {
  useDropdownMenuContext
} from './context/dropdownMenuContext'
import chainedFunction from '../utils/chainedFunction'
import useRootClose from '../hooks/useRootClose'
import arrayIndexOf from '../utils/arrayIndexOf'
import { PLACEMENT } from '../utils/constant'

const CLICK = 'click'
const HOVER = 'hover'
const CONTEXT = 'context'

const getBestPlacement = (triggerRect, menuHeight, menuWidth) => {
  const spaceAtTop = triggerRect.top
  const spaceAtBottom = window.innerHeight - triggerRect.bottom
  const spaceToLeft = triggerRect.left
  const spaceToRight = window.innerWidth - triggerRect.right
  const threshold = 10

  let verticalPlacement, horizontalPlacement

  // Determine vertical placement
  if (spaceAtTop >= menuHeight && spaceAtBottom >= menuHeight) {
    if (Math.abs(spaceAtTop - spaceAtBottom) <= threshold) {
      verticalPlacement = PLACEMENT.BOTTOM_END // Prefer bottom if spaces are almost equal
    } else {
      verticalPlacement =
        spaceAtTop > spaceAtBottom ? PLACEMENT.TOP_END : PLACEMENT.BOTTOM_END
    }
  } else if (spaceAtTop >= menuHeight) {
    verticalPlacement = PLACEMENT.TOP_END
  } else if (spaceAtBottom >= menuHeight) {
    verticalPlacement = PLACEMENT.BOTTOM_END
  } else {
    verticalPlacement =
      spaceAtBottom > spaceAtTop ? PLACEMENT.BOTTOM_END : PLACEMENT.TOP_END
  }

  // Determine horizontal placement
  if (spaceToLeft >= menuWidth && spaceToRight >= menuWidth) {
    horizontalPlacement =
      spaceToLeft > spaceToRight ? PLACEMENT.TOP_START : PLACEMENT.BOTTOM_START
  } else if (spaceToLeft >= menuWidth) {
    horizontalPlacement = PLACEMENT.TOP_START
  } else if (spaceToRight >= menuWidth) {
    horizontalPlacement = PLACEMENT.BOTTOM_START
  } else {
    horizontalPlacement =
      spaceToRight > spaceToLeft ? PLACEMENT.BOTTOM_START : PLACEMENT.TOP_START
  }

  // Combine vertical and horizontal placement for final placement decision
  if (verticalPlacement.includes('TOP')) {
    return horizontalPlacement.includes('START')
      ? PLACEMENT.TOP_START
      : PLACEMENT.TOP_END
  }
  return horizontalPlacement.includes('START')
    ? PLACEMENT.BOTTOM_START
    : PLACEMENT.BOTTOM_END
}

const Dropdown = React.forwardRef((props, ref) => {
  const {
    title,
    children,
    menuClass,
    menuStyle,
    disabled,
    renderTitle,
    placement = PLACEMENT.BOTTOM_START,
    activeKey,
    toggleClassName,
    trigger = CLICK,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onContextMenu,
    onSelect,
    onOpen,
    onClose,
    onToggle,
    visibleHeight,
    closeOnClick,
    ...rest
  } = props
  const overlayTarget = useRef()
  const triggerTarget = useRef()

  const menuControl = useDropdownMenuContext(overlayTarget)
  const { open } = menuControl

  const buttonId = useUniqueId('dropdown-toggle-')
  const menuId = useUniqueId('base-menu-')

  const [dynamicPlacement, setDynamicPlacement] = useState('bottom-end')

  const handleToggle = useCallback(
    (isOpen) => {
      const nextOpen = typeof isOpen === 'undefined' ? !open : isOpen
      const fn = nextOpen ? onOpen : onClose

      if (nextOpen && placement === PLACEMENT.AUTO && visibleHeight) {
        const triggerRect = triggerTarget.current.getBoundingClientRect()

        const bestPlacement = getBestPlacement(triggerRect, visibleHeight)

        setDynamicPlacement(bestPlacement)
      } else {
        setDynamicPlacement(placement)
      }

      fn?.()
      onToggle?.(nextOpen)

      if (nextOpen) {
        menuControl.openMenu()
      } else {
        menuControl.closeMenu()
      }
    },
    [menuControl, open, onOpen, onClose, onToggle, placement, visibleHeight]
  )

  const handleClick = useCallback(
    (e) => {
      e.preventDefault()
      if (disabled) {
        return
      }
      handleToggle()
    },
    [disabled, handleToggle]
  )

  const handleMouseEnter = useCallback(() => {
    if (!disabled) {
      handleToggle(true)
    }
  }, [disabled, handleToggle])

  const handleMouseLeave = useCallback(() => {
    if (!disabled) {
      handleToggle(false)
    }
  }, [disabled, handleToggle])

  const handleSelect = (eventKey, e) => {
    onSelect?.(eventKey, e)
    if (closeOnClick) {
      handleToggle(false)
    }
  }

  useRootClose(() => handleToggle(), {
    triggerTarget,
    overlayTarget,
    disabled: !open,
    listenEscape: false
  })

  const dropdownProps = {
    onMouseEnter,
    onMouseLeave
  }

  const toggleEventHandlers = {
    onClick,
    onContextMenu
  }

  if (arrayIndexOf(CLICK, trigger)) {
    toggleEventHandlers.onClick = chainedFunction(
      handleClick,
      toggleEventHandlers.onClick
    )
  }

  if (arrayIndexOf(CONTEXT, trigger)) {
    toggleEventHandlers.onContextMenu = chainedFunction(
      handleClick,
      onContextMenu
    )
  }

  if (arrayIndexOf(HOVER, trigger)) {
    dropdownProps.onMouseEnter = chainedFunction(handleMouseEnter, onMouseEnter)
    dropdownProps.onMouseLeave = chainedFunction(handleMouseLeave, onMouseLeave)
  }

  const toggleElement = (
    <DropdownToggle
      {...rest}
      {...toggleEventHandlers}
      id={buttonId}
      ref={triggerTarget}
      className={toggleClassName}
      renderTitle={renderTitle}
      disabled={disabled}
      placement={placement}
    >
      {title}
    </DropdownToggle>
  )

  const menuElement = (
    <DropdownMenu
      className={menuClass}
      style={menuStyle}
      onSelect={handleSelect}
      activeKey={activeKey}
      ref={overlayTarget}
      hidden={!open}
      placement={dynamicPlacement}
      id={menuId}
    >
      {children}
    </DropdownMenu>
  )

  return (
    <DropdownContext.Provider value={{ activeKey }}>
      <div {...dropdownProps} ref={ref} style={style} className="dropdown">
        {toggleElement}
        <DropdownMenuContext.Provider value={menuControl}>
          {menuElement}
        </DropdownMenuContext.Provider>
      </div>
    </DropdownContext.Provider>
  )
})

Dropdown.displayName = 'Dropdown'

const {
  TOP_START,
  TOP_CENTER,
  TOP_END,
  BOTTOM_START,
  BOTTOM_CENTER,
  BOTTOM_END,
  MIDDLE_START_TOP,
  MIDDLE_START_BOTTOM,
  MIDDLE_END_TOP,
  MIDDLE_END_BOTTOM,
  AUTO
} = PLACEMENT

Dropdown.propTypes = {
  trigger: PropTypes.oneOf([CLICK, HOVER, CONTEXT]),
  placement: PropTypes.oneOf([
    TOP_START,
    TOP_CENTER,
    TOP_END,
    BOTTOM_START,
    BOTTOM_CENTER,
    BOTTOM_END,
    MIDDLE_START_TOP,
    MIDDLE_START_BOTTOM,
    MIDDLE_END_TOP,
    MIDDLE_END_BOTTOM,
    AUTO
  ]),
  menuClass: PropTypes.string,
  menuStyle: PropTypes.object,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  renderTitle: PropTypes.node,
  activeKey: PropTypes.string,
  toggleClassName: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onContextMenu: PropTypes.func,
  onSelect: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onToggle: PropTypes.func
}

export default Dropdown
