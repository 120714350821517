import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'inventory/state',
  initialState: {
    selectedGroup: null,
    createInventoryGroupModalOpen: false,
    editInventoryGroupModalOpen: false,
    addItemsToGroupModal: {
      open: false,
      type: null,
      selectedItems: []
    },
    viewItemDrawerOpen: false,
    itemId: null,
    page: 1,
    totalItems: 0,
    hasMore: true,
    searchTerm: '',
    activeComponent: 'GROUPS',
    preferredInventoryView: 'TABLE',
    filter: {
      categories: [],
      statuses: []
    }
  },
  reducers: {
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload
    },
    setCreateInventoryGroupModalOpen: (state, action) => {
      state.createInventoryGroupModalOpen = action.payload
    },
    setEditInventoryGroupModalOpen: (state, action) => {
      state.editInventoryGroupModalOpen = action.payload
    },
    setAddItemsToGroupModal: (state, action) => {
      state.addItemsToGroupModal = action.payload
    },
    setViewItemDrawerOpen: (state, action) => {
      state.viewItemDrawerOpen = action.payload
    },
    setItemId: (state, action) => {
      state.itemId = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload
    },
    incrementTotalItems: (state, action) => {
      state.totalItems += action.payload
    },
    decrementTotalItems: (state, action) => {
      if (action.payload > 0) {
        state.totalItems -= action.payload
      } else {
        state.totalItems--
      }
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setActiveComponent: (state, action) => {
      state.activeComponent = action.payload
    },
    setPreferredInventoryView: (state, action) => {
      state.preferredInventoryView = action.payload
    },
    setInventoryFilter: (state, action) => {
      state.filter = action.payload
    }
  }
})

export const {
  setSelectedGroup,
  setCreateInventoryGroupModalOpen,
  setEditInventoryGroupModalOpen,
  setAddItemsToGroupModal,
  setViewItemDrawerOpen,
  setItemId,
  setPage,
  setTotalItems,
  incrementTotalItems,
  decrementTotalItems,
  setHasMore,
  setSearchTerm,
  setActiveComponent,
  setPreferredInventoryView,
  setInventoryFilter
} = stateSlice.actions

export default stateSlice.reducer
