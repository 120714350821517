import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Tr = React.forwardRef((props, ref) => {
  const { children, asElement: Component = 'tr', className, ...rest } = props

  const trClass = classNames(Component !== 'tr' && 'tr', className)

  return (
    <Component className={trClass} ref={ref} {...rest}>
      {children}
    </Component>
  )
})

Tr.displayName = 'Tr'

Tr.propTypes = {
  asElement: PropTypes.string
}

export default Tr
