import React, { useEffect } from 'react'
import * as UserService from '../services/UserService'
import { useDispatch } from 'react-redux'
import { setUser } from '@/store/auth/userSlice'
import { setWorkspaces } from '@/store/workspaces'
import UserContext from './user.context'
import { cloneDeep } from 'lodash'

export const UserProvider = ({ children }) => {
  const dispatch = useDispatch()

  const getUser = async () => {
    const response = await UserService.apiGetCurrentUser()

    const workspaces = cloneDeep(response?.data?.workspaces)

    if (workspaces) {
      delete response?.data?.workspaces
    }

    dispatch(setUser(response.data))
    dispatch(setWorkspaces(workspaces || []))
  }

  useEffect(() => {
    getUser()
  }, [dispatch])

  return <UserContext.Provider value={getUser}>{children}</UserContext.Provider>
}
