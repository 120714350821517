import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'tasksBoard/data',
  initialState: {
    loading: false,
    columns: {
      TODO: {
        label: 'To Do',
        data: []
      },
      IN_PROGRESS: {
        label: 'In Progress',
        data: []
      },
      IN_REVIEW: {
        label: 'In Review',
        data: []
      },
      COMPLETED: {
        label: 'Completed',
        data: []
      }
    },
    ordered: ['TODO', 'IN_PROGRESS', 'IN_REVIEW', 'COMPLETED'],
    boardMembers: [],
    allMembers: [],
    taskData: {},
    labels: [],
    filter: {
      assignees: [],
      priority: '',
      labels: [],
      sortKey: 'createdAt',
      sortOrder: 'desc'
    }
  },
  reducers: {
    updateLoading: (state, action) => {
      state.loading = action.payload
    },
    updateColumns: (state, action) => {
      state.columns = action.payload
    },
    updateOrdered: (state, action) => {
      state.ordered = action.payload
    },
    setTaskData: (state, action) => {
      state.taskData = action.payload
    },
    setTaskLabels: (state, action) => {
      state.labels = action.payload
    },
    updateComment: (state, action) => {
      const { id, hash } = action.payload

      const index = state.taskData.comments.findIndex(
        (comment) => comment.id === id || comment.hash === hash
      )

      if (index !== -1) {
        state.taskData.comments[index] = {
          ...state.taskData.comments[index],
          ...action.payload
        }
      } else {
        state.taskData.comments.push(action.payload)
      }
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    }
  }
})

export const {
  updateLoading,
  updateColumns,
  updateOrdered,
  setTaskData,
  setTaskLabels,
  updateComment,
  setFilter
} = dataSlice.actions

export default dataSlice.reducer
