import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  selectedWorkspace: null,
  workspaces: [],
  loaded: false
}

export const workspacesSlice = createSlice({
  name: 'workspaces/data',
  initialState,
  reducers: {
    setWorkspaces: (state, action) => {
      state.workspaces = action.payload
      state.loaded = true
    },
    appendWorkspace: (state, action) => {
      state.workspaces.push(action.payload)
      state.loaded = true
    },
    updateWorkspace: (state, action) => {
      const index = state.workspaces.findIndex(
        (workspace) => workspace.id === action.payload.id
      )

      if (index !== -1) {
        state.workspaces[index] = action.payload
      }
    },
    setSelectedWorkspace: (state, action) => {
      state.selectedWorkspace = action.payload
    }
  }
})

export const {
  setLoaded,
  setWorkspaces,
  appendWorkspace,
  updateWorkspace,
  setSelectedWorkspace
} = workspacesSlice.actions

export default workspacesSlice.reducer
