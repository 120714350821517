import React, { forwardRef, useEffect } from 'react'
import { TabsContextProvider } from './context'
import useControllableState from '../hooks/useControllableState'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const Tabs = forwardRef((props, ref) => {
  const {
    value: valueProp,
    onChange,
    defaultValue,
    variant = 'underline',
    className,
    useHash = true,
    ...rest
  } = props

  const [value, setValue] = useControllableState({
    prop: valueProp,
    onChange,
    defaultProp: defaultValue
  })

  const tabsClass = classNames('tabs', className)

  const location = useLocation()

  useEffect(() => {
    if (!useHash) {
      return
    }

    const hash = location.hash.split('#')[1]

    if (hash) {
      setValue(hash)
    }
  }, [location, setValue, useHash])

  return (
    <TabsContextProvider
      value={{
        value,
        onValueChange: setValue,
        variant
      }}
    >
      <div className={tabsClass} {...rest} ref={ref} />
    </TabsContextProvider>
  )
})

Tabs.displayName = 'Tabs'

Tabs.propTypes = {
  variant: PropTypes.oneOf(['underline', 'pill']),
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default Tabs
