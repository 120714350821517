import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useConfig } from '../ConfigProvider'
import { useForm } from '../Form/context'
import { useInputGroup } from '../InputGroup/context'
import useColorLevel from '../hooks/useColorLevel'
import { CONTROL_SIZES, SIZES } from '../utils/constant'
import Spinner from '../Spinner'

const Button = React.forwardRef((props, ref) => {
  const {
    children,
    size,
    color = '',
    shape = 'round',
    variant = 'default',
    block,
    icon,
    className,
    disabled = false,
    loading = false,
    active = false,
    ...rest
  } = props
  const { themeColor, controlSize, primaryColorLevel } = useConfig()
  const formControlSize = useForm()?.size
  const inputGroupSize = useInputGroup()?.size
  const defaultClass = 'button'
  const sizeIconClass = 'inline-flex items-center justify-center'

  const splitedColor = color.split('-')

  const buttonSize = size || inputGroupSize || formControlSize || controlSize
  const buttonColor = splitedColor[0] || themeColor
  const buttonColorLevel = splitedColor[1] || primaryColorLevel

  const [increaseLevel, decreaseLevel] = useColorLevel(buttonColorLevel)

  const getButtonSize = () => {
    let sizeClass = ''
    switch (buttonSize) {
      case SIZES.LG:
        sizeClass = classNames(
          `h-${CONTROL_SIZES.lg}`,
          icon && !children
            ? `w-${CONTROL_SIZES.lg} ${sizeIconClass} text-2xl`
            : 'px-8 py-2 text-base'
        )
        break
      case SIZES.SM:
        sizeClass = classNames(
          `h-${CONTROL_SIZES.sm}`,
          icon && !children
            ? `w-${CONTROL_SIZES.sm} ${sizeIconClass} text-lg`
            : 'px-3 py-2 text-sm'
        )
        break
      case SIZES.XS:
        sizeClass = classNames(
          `h-${CONTROL_SIZES.xs}`,
          icon && !children
            ? `w-${CONTROL_SIZES.xs} ${sizeIconClass} text-base`
            : 'px-3 py-1 text-xs'
        )
        break
      default:
        sizeClass = classNames(
          `h-${CONTROL_SIZES.md}`,
          icon && !children
            ? `w-${CONTROL_SIZES.md} ${sizeIconClass} text-xl`
            : 'px-8 py-2'
        )
        break
    }
    return sizeClass
  }

  const disabledClass = 'opacity-70 cursor-not-allowed'

  const getBtnColor = ({ bgColor, hoverColor, activeColor, textColor }) => {
    return `${bgColor} ${
      disabled || loading ? disabledClass : `${hoverColor} ${activeColor}`
    } ${textColor}`
  }

  const solidColor = () => {
    const btn = {
      bgColor: active
        ? `bg-${buttonColor}-${increaseLevel}`
        : `bg-${buttonColor}-${buttonColorLevel}`,
      textColor: 'text-white',
      hoverColor: active
        ? ''
        : `hover:bg-${buttonColor}-${decreaseLevel} hover:transition-colors hover:duration-200`,
      activeColor: `active:bg-${buttonColor}-${increaseLevel}`
    }
    return getBtnColor(btn)
  }

  const twoToneColor = () => {
    const btn = {
      bgColor: active ? `bg-${buttonColor}-200` : `bg-${buttonColor}-50`,
      textColor: `text-${buttonColor}-${buttonColorLevel}`,
      hoverColor: active
        ? ''
        : `hover:bg-${buttonColor}-100 hover:transition-colors hover:duration-200`,
      activeColor: `active:bg-${buttonColor}-200`
    }
    return getBtnColor(btn)
  }

  const defaultColor = () => {
    const btn = {
      bgColor: active
        ? `bg-gray-50 border border-gray-100`
        : `bg-white border border-gray-100`,
      textColor: `text-gray-600`,
      hoverColor: active
        ? ''
        : `hover:bg-gray-100/50 hover:transition-colors hover:duration-200`,
      activeColor: `active:bg-gray-50`
    }
    return getBtnColor(btn)
  }

  const plainColor = () => {
    const btn = {
      bgColor: active
        ? `bg-gray-50`
        : 'bg-transparent border border-transparent',
      textColor: `text-gray-600`,
      hoverColor: active
        ? ''
        : `hover:bg-gray-100/50 hover:transition-colors hover:duration-200`,
      activeColor: `active:bg-gray-50`
    }
    return getBtnColor(btn)
  }

  const btnColor = () => {
    switch (variant) {
      case 'solid':
        return solidColor()
      case 'twoTone':
        return twoToneColor()
      case 'plain':
        return plainColor()
      case 'default':
        return defaultColor()
      default:
        return defaultColor()
    }
  }

  const classes = classNames(
    defaultClass,
    btnColor(),
    `radius-${shape}`,
    getButtonSize(),
    className,
    block ? 'w-full' : ''
  )

  const handleClick = (e) => {
    const { onClick } = props
    if (disabled || loading) {
      e.preventDefault()
      return
    }

    onClick && onClick(e)
  }

  const renderChildren = () => {
    if (loading && children) {
      return (
        <span className="flex items-center justify-center">
          <Spinner enableTheme={false} className="mr-1" />
          {children}
        </span>
      )
    }

    if (icon && !children && loading) {
      return <Spinner enableTheme={false} />
    }

    if (icon && !children && !loading) {
      return <>{icon}</>
    }

    if (icon && children && !loading) {
      return (
        <span className="flex items-center justify-center">
          <span className="text-lg">{icon}</span>
          <span className="ltr:ml-1 rtl:mr-1">{children}</span>
        </span>
      )
    }

    return <>{children}</>
  }

  return (
    <button ref={ref} className={classes} {...rest} onClick={handleClick}>
      {renderChildren()}
    </button>
  )
})

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  shape: PropTypes.oneOf(['round', 'circle', 'none']),
  className: PropTypes.string,
  size: PropTypes.oneOf([SIZES.LG, SIZES.SM, SIZES.XS, SIZES.MD]),
  color: PropTypes.string,
  variant: PropTypes.oneOf(['solid', 'twoTone', 'plain', 'default']),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  active: PropTypes.bool
}

export default Button
