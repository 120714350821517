import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'calendar/data',
  initialState: {
    events: {
      events: [],
      holidays: []
    },
    labels: [],
    eventDialogData: {},
    filter: {
      types: [],
      labels: [],
      otherCalendarId: null
    }
  },
  reducers: {
    setEvents: (state, action) => {
      state.events.events = action.payload
    },
    setHolidays: (state, action) => {
      state.events.holidays = action.payload
    },
    setCalendarLabels: (state, action) => {
      state.labels = action.payload
    },
    setEventDialogData: (state, action) => {
      state.eventDialogData = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    }
  }
})

export const {
  setEvents,
  setHolidays,
  setCalendarLabels,
  setEventDialogData,
  setFilter
} = dataSlice.actions

export default dataSlice.reducer
