import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from '@/components/layout/Theme/Theme'
import Layout from '@/components/layout'
import history from './history'
import './locales'
import { WorkspaceProvider } from '@/providers/workspace.provider'
import { UserProvider } from '@/providers/user.provider'
import { SocketProvider } from '@/providers/socket.provider'
import { FeedbackProvider } from '@/providers/feedback.provider'
import OnboardingProvider from './providers/onboarding.provider'
import { CustomLoader } from './components/shared/utils/CustomLoading'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history}>
          <Theme>
            <UserProvider>
              <WorkspaceProvider>
                <SocketProvider>
                  <FeedbackProvider>
                    <Suspense
                      fallback={
                        <div className="flex h-screen items-center justify-center">
                          <CustomLoader size="xl" />
                        </div>
                      }
                    >
                      <OnboardingProvider>
                        <Layout />
                      </OnboardingProvider>
                    </Suspense>
                  </FeedbackProvider>
                </SocketProvider>
              </WorkspaceProvider>
            </UserProvider>
          </Theme>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
