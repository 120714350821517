import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'projectList/state',
  initialState: {
    query: {
      search: ''
    },
    newProjectDialog: false,
    prefillProjectData: null,
    customerModalOpen: false,
    customer: null
  },
  reducers: {
    setSearch: (state, action) => {
      state.query.search = action.payload
    },
    toggleNewProjectDialog: (state, action) => {
      state.newProjectDialog = action.payload
    },
    setPrefillProjectData: (state, action) => {
      state.prefillProjectData = action.payload
    },
    setCustomerModalOpen: (state, action) => {
      state.customerModalOpen = action.payload
    },
    setCustomer: (state, action) => {
      state.customer = action.payload
    }
  }
})

export const {
  toggleNewProjectDialog,
  setSearch,
  setPrefillProjectData,
  setCustomerModalOpen,
  setCustomer
} = stateSlice.actions

export default stateSlice.reducer
