import ApiService from './ApiService'

export async function apiGetCurrentUser() {
  return ApiService.fetchData({
    url: 'users/me',
    method: 'get'
  })
}

export async function apiUpdateUser(userId, data) {
  return ApiService.fetchData({
    url: `users/${userId}`,
    method: 'patch',
    data
  })
}
