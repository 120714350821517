import ApiService from './ApiService'

export async function apiGetSuggestions(search) {
  const params = new URLSearchParams()
  if (search) {
    params.append('search', search)
  }

  return ApiService.fetchData({
    url: `feedback/suggestions?${params.toString()}`,
    method: 'get'
  })
}

export async function apiSubmitSuggestion(data) {
  return ApiService.fetchData({
    url: `feedback/suggestions`,
    method: 'post',
    data
  })
}

export async function apiVoteSuggestion(id, vote) {
  return ApiService.fetchData({
    url: `feedback/suggestions/${id}`,
    method: 'post',
    data: { vote }
  })
}

export async function apiSubmitFeedback(data) {
  return ApiService.fetchData({
    url: `feedback`,
    method: 'post',
    data: { feedback: data }
  })
}
