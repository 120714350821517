import BaseService from './BaseService'

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      let { url } = param
      const { data, body } = param

      delete param.url

      if (url.startsWith('/')) {
        url = url.substring(1)
      }

      BaseService(url, {
        ...param,
        json: data,
        body
      })
        .then((response) => {
          resolve(response)
        })
        .catch((errors) => {
          reject(errors)
        })
    })
  }
}

export default ApiService
