import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'project/state',
  initialState: {
    id: '',
    dialogOpen: false,
    taskId: '',
    board: '',
    selectedTab: 'All',
    filterPriceRange: [0, 1000],
    page: 1,
    hasMore: true,
    showExpensesFilter: false
  },
  reducers: {
    setProjectId: (state, action) => {
      state.id = action.payload
    },
    setFilterPriceRange: (state, action) => {
      state.filterPriceRange = action.payload
    },
    setPage: (state, action) => {
      state.page = action.payload
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload
    },
    setShowExpensesFilter: (state) => {
      state.showExpensesFilter = !state.showExpensesFilter
    }
  }
})

export const {
  setProjectId,
  setFilterPriceRange,
  setPage,
  setHasMore,
  setShowExpensesFilter
} = stateSlice.actions

export default stateSlice.reducer
