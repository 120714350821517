import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from '@/store/auth/userSlice'
import {
  apiDisableTwoAuth,
  apiEnableTwoAuth,
  apiGenerateSecret,
  apiSignIn,
  apiSignOut,
  apiSignUp,
  apiRequestVerification
} from '@/services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from '@/store/auth/sessionSlice'
import appConfig from '@/configs/app.config'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const query = useQuery()

  const { tokens, signedIn } = useSelector((state) => state.auth.session)

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values)

      if (resp.status === 200) {
        const { tokens } = resp.data
        dispatch(onSignInSuccess(tokens))
        if (resp?.data?.user) {
          dispatch(
            setUser(
              resp?.data?.user || {
                avatar: '',
                name: 'Guest',
                authority: ['USER'],
                email: ''
              }
            )
          )
        }

        let redirectUrl = query.get(REDIRECT_URL_KEY)
        if (!redirectUrl) {
          redirectUrl = localStorage.getItem(REDIRECT_URL_KEY)
          localStorage.removeItem(REDIRECT_URL_KEY)
        }

        navigate(redirectUrl || appConfig.authenticatedEntryPath)
        return {
          status: 'success',
          message: ''
        }
      }
      return {
        status: 'failed',
        statusCode: resp.status,
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values)

      if (resp.status === 201) {
        return {
          status: 'success',
          message: ''
        }
      }

      return {
        status: 'failed',
        statusCode: resp.status,
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        statusCode: error?.response?.status,
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const handleSignOut = () => {
    dispatch(onSignOutSuccess())
    dispatch(setUser(initialState))
    navigate(appConfig.unAuthenticatedEntryPath)
  }

  const signOut = async () => {
    await apiSignOut()
    handleSignOut()
  }

  // 2FA

  const generateSecret = async () => {
    try {
      const resp = await apiGenerateSecret()

      if (resp.status === 200) {
        return {
          status: 'success',
          message: '',
          qrCode: resp?.data?.qr
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const enableTwoAuth = async (code) => {
    try {
      const resp = await apiEnableTwoAuth({ code })

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const disableTwoAuth = async (code) => {
    try {
      const resp = await apiDisableTwoAuth({ code })

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  const requestVerification = async (phone) => {
    try {
      const resp = await apiRequestVerification({ phone })

      if (resp.status === 200) {
        return {
          status: 'success',
          message: ''
        }
      }
      return {
        status: 'failed',
        message: resp?.data?.message || resp.toString()
      }
    } catch (error) {
      return {
        status: 'failed',
        message: error?.response?.data?.message || error.toString()
      }
    }
  }

  return {
    authenticated: tokens && signedIn,
    signIn,
    signUp,
    signOut,
    generateSecret,
    enableTwoAuth,
    disableTwoAuth,
    requestVerification
  }
}

export default useAuth
