import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'manageWorkspace/state',
  initialState: {
    drawerOpen: false,
    drawerAction: 'edit',
    selectedMember: {},
    inviteMemberDialogOpen: false,
    hasMore: false,
    showImage: null
  },
  reducers: {
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false
    },
    setInviteMemberDialogOpen: (state) => {
      state.inviteMemberDialogOpen = true
    },
    setInviteMemberDialogClose: (state) => {
      state.inviteMemberDialogOpen = false
    },
    setDrawerAction: (state, action) => {
      state.drawerAction = action.payload
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload
    },
    setShowImage: (state, action) => {
      state.showImage = action.payload
    }
  }
})

export const {
  setSelectedMember,
  setDrawerOpen,
  setDrawerClose,
  setInviteMemberDialogOpen,
  setInviteMemberDialogClose,
  setDrawerAction,
  setHasMore,
  setShowImage
} = stateSlice.actions

export default stateSlice.reducer
