import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'inventory/data',
  initialState: {
    items: [],
    item: null,
    loading: false,
    groups: []
  },
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload
    },
    setItem: (state, action) => {
      state.item = action.payload
    },
    updateItem: (state, action) => {
      if (state.item && state.item.id === action.payload.id) {
        state.item = {
          ...state.item,
          ...action.payload
        }
      }
    },
    appendItems: (state, action) => {
      state.items = [...state.items, ...action.payload]
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setInventoryGroups: (state, action) => {
      state.groups = action.payload
    },
    updateGroup: (state, action) => {
      const group = state.groups.find((group) => group.id === action.payload.id)

      if (group) {
        Object.assign(group, action.payload)
      }
    },
    removeItemsFromGroups: (state, action) => {
      for (const id of action.payload) {
        const itemIndex = state.items.findIndex((e) => e.id === id)

        if (itemIndex === -1) return

        for (const group of state.items[itemIndex].groups) {
          const groupIndex = state.groups.findIndex((g) => g.id === group.id)

          if (groupIndex !== -1) {
            state.groups[groupIndex].items.filter((i) => i.id !== id)
          }
        }

        state.items.splice(itemIndex, 1)
      }
    },
    removeItemsFromGroup: (state, action) => {
      const groupIndex = state.groups.findIndex(
        (g) => g.id === action.payload.group
      )

      for (const id of action.payload.ids) {
        const itemIndex = state.items.findIndex(
          (e) =>
            e.id === id && e.groups.some((g) => g.id === action.payload.group)
        )

        if (itemIndex === -1) return

        if (groupIndex !== -1) {
          state.groups[groupIndex].items.filter((i) => i.id !== id)
        }

        state.items.splice(itemIndex, 1)
      }
    },
    deleteItem: (state, action) => {
      const itemIndex = state.items.findIndex((e) => e.id === action.payload)

      if (itemIndex === -1) return

      const itemGroups = state.items[itemIndex].groups

      if (itemGroups && Array.isArray(itemGroups)) {
        for (const group of itemGroups) {
          const groupIndex = state.groups.findIndex((g) => g.id === group.id)

          if (groupIndex !== -1) {
            state.groups[groupIndex].items.filter(
              (i) => i.id !== action.payload
            )
          }
        }
      }

      state.items.splice(itemIndex, 1)
    }
  }
})

export const {
  setItems,
  setItem,
  updateItem,
  appendItems,
  setLoading,
  setInventoryGroups,
  updateGroup,
  removeItemsFromGroups,
  removeItemsFromGroup,
  deleteItem
} = dataSlice.actions

export default dataSlice.reducer
