import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'project/data',
  initialState: {
    id: null,
    name: null,
    shortDescription: null,
    description: null,
    members: [],
    creator: null,
    workspaceId: null,
    files: [],
    loading: false,
    contacts: [],
    customer: null,
    expenses: [],
    labels: [],
    relatedOffers: [],
    currency: '',
    budget: '',
    timeTrackingData: []
  },
  reducers: {
    updateProjectData: (state, action) => {
      for (const key in action.payload) {
        state[key] = action.payload[key]
      }
    },
    setExpenses: (state, action) => {
      state.expenses = action.payload
    },
    setExpensesLabels: (state, action) => {
      state.labels = action.payload
    },
    appendExpenses: (state, action) => {
      state.expenses = {
        ...state.expenses,
        data: [...state.expenses.data, ...action.payload]
      }
    },
    setTimeTrackingData: (state, action) => {
      state.timeTrackingData = action.payload
    }
  }
})

export const {
  updateProjectData,
  setExpenses,
  setExpensesLabels,
  appendExpenses,
  setTimeTrackingData
} = dataSlice.actions

export default dataSlice.reducer
