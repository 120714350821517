import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Table = React.forwardRef((props, ref) => {
  const {
    borderlessRow = false,
    children,
    className,
    hoverable = true,
    compact = false,
    oveerflow = false,
    asElement: Component = 'table',
    ...rest
  } = props

  const tableClass = classNames(
    Component === 'table' ? 'table-default' : 'table-flex',
    hoverable && 'table-hover',
    compact && 'table-compact',
    borderlessRow && 'borderless-row',
    className
  )

  return (
    <div className={classNames(oveerflow && 'overflow-x-auto')}>
      <Component className={tableClass} {...rest} ref={ref}>
        {children}
      </Component>
    </div>
  )
})

Table.displayName = 'Table'

Table.propTypes = {
  hoverable: PropTypes.bool,
  compact: PropTypes.bool,
  asElement: PropTypes.string,
  borderlessRow: PropTypes.bool
}

export default Table
