import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useConfig } from '../ConfigProvider'
import { HiCheck, HiX } from 'react-icons/hi'
import { STEPS_STATUS } from '../utils/constant'

const { COMPLETE, PENDING, IN_PROGRESS, ERROR } = STEPS_STATUS

const STEP_STATUS_ICON = {
  [COMPLETE]: <HiCheck />,
  [PENDING]: null,
  [IN_PROGRESS]: null,
  [ERROR]: <HiX />
}

const StepItem = forwardRef((props, ref) => {
  const {
    className,
    status,
    customIcon,
    stepNumber,
    title,
    isLast,
    vertical,
    onStepChange,
    percentage,
    header,
    ...rest
  } = props

  const { themeColor, primaryColorLevel } = useConfig()

  const color = `${themeColor}-${primaryColorLevel}`

  let stepIcon = <span>{STEP_STATUS_ICON[status] ?? stepNumber}</span>

  if (customIcon) {
    stepIcon = <span>{customIcon}</span>
  }

  const stepItemClass = classNames(
    `step-item step-item-${status}`,
    vertical && 'step-item-vertical',
    className
  )

  const stepWrapperClass = classNames(
    'step-item-wrapper',
    onStepChange && 'step-clickable'
  )

  const stepIconClass = classNames(
    `step-item-icon step-item-icon-${status}`,
    status === COMPLETE && `bg-${color} text-white`,
    status === ERROR && `step-item-icon-error`,
    status === IN_PROGRESS &&
      `text-${color} border-${color} step-item-icon-current`
  )

  const handleStepChange = () => {
    onStepChange?.()
  }

  return (
    <div
      className={stepItemClass}
      {...rest}
      ref={ref}
      onClick={handleStepChange}
    >
      <div className={stepWrapperClass}>
        {header && (
          <div className="relative flex justify-center items-center">
            <div className="absolute font-bold text-center pb-4 w-max">
              {header}
            </div>
          </div>
        )}

        <div className={stepIconClass}>{stepIcon}</div>

        {title && (
          <div className="relative flex justify-center items-center">
            <div className="absolute font-semibold text-center pt-4">
              {title}
            </div>
          </div>
        )}
      </div>
      {!isLast && (
        <div className="w-full relative">
          <div
            className={classNames(
              'step-connect',
              vertical && 'step-connect-vertical',
              'inactive'
            )}
          />
          <div
            className={classNames(
              'step-connect',
              vertical && 'step-connect-vertical',
              `bg-${color}`,
              'active'
            )}
            style={{
              width: vertical ? '100%' : `${percentage}%`
            }}
          />
        </div>
      )}
    </div>
  )
})

StepItem.displayName = 'StepItem'

StepItem.propTypes = {
  vertical: PropTypes.bool,
  stepNumber: PropTypes.number,
  status: PropTypes.oneOf([COMPLETE, PENDING, IN_PROGRESS, ERROR]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  customIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  percentage: PropTypes.number,
  isLast: PropTypes.bool
}

export default StepItem
