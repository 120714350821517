import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import workspace from './workspace'
import workspaces from './workspaces'
import manageWorkspace from '@/views/manageWorkspace/store'
import project from '@/views/projectDetail/store'
import inventory from '@/views/inventory/store'
import employees from '@/views/employees/store'
import customers from '@/views/customers/store'
import offers from '@/views/projects/store/offers'
import projectList from '@/views/projects/store'
import calendar from '@/views/calendar/store'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    locale,
    workspace,
    workspaces,
    manageWorkspace,
    project,
    inventory,
    employees,
    customers,
    offers,
    projectList,
    calendar,
    ...asyncReducers
  })
  return combinedReducer(state, action)
}

export default rootReducer
