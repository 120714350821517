import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
  name: 'tasksBoard/state',
  initialState: {
    taskId: '',
    selectedTab: 'All',
    isTaskContentOpen: false,
    isConfirmDialogOpen: false,
    confirmDialogType: '',
    confirmDialogId: '',
    preferredView: 'BOARD'
  },
  reducers: {
    setSelectedTaskId: (state, action) => {
      state.taskId = action.payload
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload
    },
    setIsTaskContentOpen: (state, action) => {
      state.isTaskContentOpen = action.payload
    },
    setIsConfirmDialogOpen: (state, action) => {
      state.isConfirmDialogOpen = action.payload
    },
    setConfirmDialogType: (state, action) => {
      state.confirmDialogType = action.payload
    },
    setConfirmDialogId: (state, action) => {
      state.confirmDialogId = action.payload
    },
    setPreferredTasksView: (state, action) => {
      state.preferredView = action.payload
    }
  }
})

export const {
  setSelectedTaskId,
  setSelectedTab,
  setIsTaskContentOpen,
  setIsConfirmDialogOpen,
  setConfirmDialogType,
  setConfirmDialogId,
  setPreferredTasksView
} = stateSlice.actions

export default stateSlice.reducer
