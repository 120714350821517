import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://6db94beb4e9152e4b513d456df018ced@o4506354432671744.ingest.sentry.io/4506354433982464',
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })

  ReactGA.initialize('G-752F43TL59')

  TagManager.initialize({ gtmId: 'GTM-P9J394TM' })
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

if ('serviceWorker' in navigator && 'PushManager' in window) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(() => {
        return Notification.requestPermission()
      })
      .then((permission) => {
        if (permission === 'granted') {
          return navigator.serviceWorker.ready
        }
        throw new Error('Permission not granted for Notification!')
      })
      .then(async (registration) => {
        const subscription = await registration.pushManager.getSubscription()

        if (subscription) {
          return null
        }

        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            'BJEiz4NftZWNkh40UqseIY2terfX3zmsDlbK3rf5LEQXp_U7InjsZ0A5baHCX4J5eOfp4pWg1df-BcoAuh6maDE'
        })
      })
      .then((subscription) => {
        if (subscription) {
          fetch(`${import.meta.env.VITE_API_PREFIX}/notifications/subscribe`, {
            method: 'POST',
            body: JSON.stringify(subscription),
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: 'include'
          })
        }
      })
      .catch(() => {})
  })
} else {
  console.warn('Push messaging is not supported')
}
