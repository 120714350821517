import { combineReducers } from '@reduxjs/toolkit'
import data from './dataSlice'
import state from './stateSlice'
import tasksBoard from '../components/TasksBoard/store'

const reducer = combineReducers({
  data,
  state,
  tasksBoard
})

export default reducer
