import React, { memo, useMemo, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_BLANK
} from '@/constants/theme.constant'
import useAuth from '@/utils/hooks/useAuth'
import useDirection from '@/utils/hooks/useDirection'
import useLocale from '@/utils/hooks/useLocale'
import { useLocation } from 'react-router-dom'
import { Notifications } from '@bdhamithkumara/react-push-notification'
import { CustomLoader } from '../shared/utils/CustomLoading'

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout'))
}

const BLANK_LAYOUT_PATHS = ['/setup']

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type)

  const { authenticated } = useAuth()
  const location = useLocation()

  useDirection()

  useLocale()

  const AppLayout = useMemo(() => {
    if (authenticated) {
      if (BLANK_LAYOUT_PATHS.includes(location.pathname)) {
        return layouts[LAYOUT_TYPE_BLANK]
      }

      return layouts[layoutType]
    }

    return lazy(() => import('./AuthLayout'))
  }, [layoutType, authenticated, location.pathname])

  return (
    <Suspense
      fallback={
        <div className="flex h-screen items-center justify-center">
          <CustomLoader size="xl" />
        </div>
      }
    >
      <Notifications />
      <AppLayout />
    </Suspense>
  )
}

export default memo(Layout)
