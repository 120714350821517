import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import i18n from 'i18next'
import { dateLocales } from '@/locales'
import dayjs from 'dayjs'

function useLocale() {
  const initialLocale = useSelector((state) => state.locale.currentLang)
  const localeRef = useRef(initialLocale)

  useEffect(() => {
    if (!localeRef.current) {
      localeRef.current = window.localStorage.getItem('lang') || 'en'
    }

    const formattedLang = localeRef.current.replace(/-([a-z])/g, (g) => {
      return g[1].toUpperCase()
    })

    if (localeRef.current !== i18n.language) {
      i18n.changeLanguage(formattedLang)
    }

    dateLocales[formattedLang]().then(() => {
      dayjs.locale(formattedLang)
    })
  }, [localeRef])

  return localeRef.current
}

export default useLocale
