import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'employees/data',
  initialState: {
    employees: [],
    employee: null,
    loading: false,
    groups: []
  },
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload
    },
    setEmployee: (state, action) => {
      state.employee = action.payload
    },
    updateEmployee: (state, action) => {
      if (state.employee && state.employee.id === action.payload.id) {
        state.employee = {
          ...state.employee,
          ...action.payload
        }
      }
    },
    appendEmployees: (state, action) => {
      state.employees = [...state.employees, ...action.payload]
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setEmployeeGroups: (state, action) => {
      state.groups = action.payload
    },
    updateGroup: (state, action) => {
      const group = state.groups.find((group) => group.id === action.payload.id)

      if (group) {
        Object.assign(group, action.payload)
      }
    },
    removeEmployeesFromGroups: (state, action) => {
      for (const id of action.payload) {
        const employeeIndex = state.employees.findIndex((e) => e.id === id)

        if (employeeIndex === -1) return

        for (const group of state.employees[employeeIndex].groups) {
          const groupIndex = state.groups.findIndex((g) => g.id === group.id)

          if (groupIndex !== -1) {
            state.groups[groupIndex].employees.filter((e) => e.id !== id)
          }
        }

        state.employees.splice(employeeIndex, 1)
      }
    },
    removeEmployeesFromGroup: (state, action) => {
      const groupIndex = state.groups.findIndex(
        (g) => g.id === action.payload.group
      )

      for (const id of action.payload.ids) {
        const employeeIndex = state.employees.findIndex(
          (e) =>
            e.id === id && e.groups.some((g) => g.id === action.payload.group)
        )

        if (employeeIndex === -1) return

        if (groupIndex !== -1) {
          state.groups[groupIndex].employees.filter((e) => e.id !== id)
        }

        state.employees.splice(employeeIndex, 1)
      }
    },
    deleteEmployee: (state, action) => {
      const employeeIndex = state.employees.findIndex(
        (e) => e.id === action.payload
      )

      if (employeeIndex === -1) return

      const employeeGroups = state.employees[employeeIndex]?.groups

      if (employeeGroups && Array.isArray(employeeGroups)) {
        for (const group of employeeGroups) {
          const groupIndex = state.groups.findIndex((g) => g.id === group.id)

          if (groupIndex !== -1) {
            state.groups[groupIndex].employees.filter(
              (e) => e.id !== action.payload
            )
          }
        }
      }

      state.employees.splice(employeeIndex, 1)
    }
  }
})

export const {
  setEmployees,
  setEmployee,
  updateEmployee,
  appendEmployees,
  setLoading,
  setEmployeeGroups,
  updateGroup,
  removeEmployeesFromGroups,
  removeEmployeesFromGroup,
  deleteEmployee
} = dataSlice.actions

export default dataSlice.reducer
