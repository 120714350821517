import React, { useState, useEffect } from 'react'
import socketIo from 'socket.io-client'
import SocketContext from './socket.context'
import { useSelector } from 'react-redux'
import appConfig from '@/configs/app.config'

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null)
  const [socketConnected, setSocketConnected] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [heartbeatInterval, setHeartbeatInterval] = useState(null)

  const locale = useSelector((state) => state?.locale)

  useEffect(() => {
    // get access token from cookie (_nex_at)
    const accessToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('_nex_at'))
      ?.split('=')[1]

    setAccessToken(accessToken)
  }, [document.cookie])

  useEffect(() => {
    if (accessToken) {
      setSocket(
        socketIo.connect(appConfig.socketPrefix, {
          transports: ['websocket'],
          auth: {
            token: accessToken
          }
        })
      )
    }
  }, [accessToken])

  useEffect(() => {
    if (socket && locale.currentLang) {
      socket.emit('set-language', locale.currentLang)
    }
  }, [socket, locale.currentLang])

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        console.log('connected')

        setSocketConnected(true)

        let lang = window.localStorage.getItem('lang')

        if (locale.currentLang) {
          lang = locale.currentLang
        }

        socket.emit('set-language', lang || 'en')
      })

      socket.on('disconnect', () => {
        console.log('disconnected')

        setSocketConnected(false)
      })

      socket.on('error', (error) => {
        console.log(error)
      })
    }

    return () => {
      if (socket) {
        console.log('disconnecting')
        socket.disconnect()
      }
    }
  }, [socket])

  useEffect(() => {
    if (socket && socketConnected && !heartbeatInterval) {
      setHeartbeatInterval(
        setInterval(() => {
          socket.emit('heartbeat')
        }, 15000)
      )
    }

    return () => {
      if (socket && heartbeatInterval) {
        clearInterval(heartbeatInterval)

        setHeartbeatInterval(null)
      }
    }
  }, [socket, socketConnected, heartbeatInterval])

  return (
    <SocketContext.Provider
      value={{
        socket,
        socketConnected
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}
