import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Line from './Line'
import Circle from './Circle'
import { useConfig } from '../ConfigProvider'

const Progress = React.forwardRef((props, ref) => {
  const {
    variant = 'line',
    color,
    percent = 0,
    showInfo = true,
    customInfo,
    className,
    width = 120
  } = props

  const { themeColor, primaryColorLevel } = useConfig()

  const renderProcessInfo = () => {
    if (!showInfo) {
      return null
    }
    return (
      <span className={`progress-info ${variant}`}>
        {customInfo || `${percent}%`}
      </span>
    )
  }

  const strokeColor = color || `${themeColor}-${primaryColorLevel}`

  const progressTrailColor = 'gray-100'

  const progressClass = classNames(
    'progress',
    className,
    variant === 'circle' ? 'circle' : 'line'
  )

  const renderProgress = (direction) => {
    const progressInfo = renderProcessInfo()
    let progress

    if (variant === 'line') {
      progress = (
        <Line
          strokeColor={strokeColor}
          direction={direction}
          trailColor={progressTrailColor}
          {...props}
        >
          {progressInfo}
        </Line>
      )
    }

    if (variant === 'circle') {
      progress = (
        <Circle
          strokeColor={strokeColor}
          trailColor={progressTrailColor}
          width={width}
          {...props}
        >
          {progressInfo}
        </Circle>
      )
    }

    return progress
  }

  return (
    <div ref={ref} className={progressClass}>
      {renderProgress()}
    </div>
  )
})

Progress.displayName = 'Progress'

Progress.propTypes = {
  color: PropTypes.string,
  customInfo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  percent: PropTypes.number,
  showInfo: PropTypes.bool,
  variant: PropTypes.oneOf(['line', 'circle']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Progress
