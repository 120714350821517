import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

const stateSlice = createSlice({
  name: 'calendar/state',
  initialState: {
    eventDialogOpened: false,
    eventDialogType: 'NEW',
    viewDialogOpened: false,
    view: 'month',
    monthToShow: dayjs().format('YYYY-MM'),
    weekDateRangeToShow: {
      start: dayjs().startOf('isoWeek').hour(0).minute(0).second(0),
      end: dayjs().endOf('isoWeek').hour(23).minute(59).second(59)
    },
    showPublicHolidays: true,
    showFilter: false
  },
  reducers: {
    setEventDialogOpened(state, action) {
      state.eventDialogOpened = action.payload
    },
    setEventDialogType(state, action) {
      state.eventDialogType = action.payload
    },
    setViewDialogOpened(state, action) {
      state.viewDialogOpened = action.payload
    },
    setView(state, action) {
      state.view = action.payload
    },
    setMonthToShow(state, action) {
      state.monthToShow = action.payload
    },
    setWeekDateRangeToShow(state, action) {
      state.weekDateRangeToShow = action.payload
    },
    shiftWeekForward(state) {
      state.weekDateRangeToShow = {
        start: dayjs(state.weekDateRangeToShow.start)
          .add(1, 'week')
          .hour(0)
          .minute(0)
          .second(0),

        end: dayjs(state.weekDateRangeToShow.end)
          .add(1, 'week')
          .hour(23)
          .minute(59)
          .second(59)
      }
    },
    shiftWeekBack(state) {
      state.weekDateRangeToShow = {
        start: dayjs(state.weekDateRangeToShow.start)
          .subtract(1, 'week')
          .hour(0)
          .minute(0)
          .second(0),

        end: dayjs(state.weekDateRangeToShow.end)
          .subtract(1, 'week')
          .hour(23)
          .minute(59)
          .second(59)
      }
    },
    resetWeekAndMonth(state) {
      state.weekDateRangeToShow = {
        start: dayjs().startOf('isoWeek').hour(0).minute(0).second(0),
        end: dayjs().endOf('isoWeek').hour(23).minute(59).second(59)
      }
      state.monthToShow = dayjs().format('YYYY-MM')
    },
    togglePublicHolidays(state) {
      state.showPublicHolidays = !state.showPublicHolidays
    },
    toggleFilter(state) {
      state.showFilter = !state.showFilter
    }
  }
})

export const {
  setEventDialogOpened,
  setEventDialogType,
  setViewDialogOpened,
  setView,
  setMonthToShow,
  setWeekDateRangeToShow,
  shiftWeekForward,
  shiftWeekBack,
  resetWeekAndMonth,
  togglePublicHolidays,
  toggleFilter
} = stateSlice.actions

export default stateSlice.reducer
