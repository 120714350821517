export const getOnboardingSteps = (t, type) => {
  if (type === 'INITIAL') {
    return [
      {
        target: '[data-testid="home"]',
        placement: 'center',
        title: t('onboarding.initial.1.title', 'All-in-One Overview'),
        content: t(
          'onboarding.initial.1.content',
          'Welcome! This is your main dashboard. Here you can see important business information, upcoming events, and team updates at a glance.'
        ),
        disableBeacon: true,
        path: '/home'
      },
      {
        target: '[data-testid="notifications"]',
        title: t('onboarding.initial.2.title', 'Notifications'),
        content: t(
          'onboarding.initial.2.content',
          'Stay informed about your business. Check here for updates on projects, events, and other important alerts.'
        ),
        path: '/home'
      },
      {
        target: '[data-testid="home-calendar-filter"]',
        title: t('onboarding.initial.3.title', 'Calendar Filters'),
        content: t(
          'onboarding.initial.3.content',
          'Organize your schedule better. Use these options to show only the events you want to see.'
        ),
        path: '/home'
      },
      {
        target: '[data-testid="home-calendar-create"]',
        title: t('onboarding.initial.4.title', 'Add New Events'),
        content: t(
          'onboarding.initial.4.content',
          'Need to schedule something? Click here to add a new event to your calendar.'
        ),
        path: '/home',
        data: {
          next: '/projects'
        }
      },
      {
        target: '[data-testid="projects"]',
        placement: 'center',
        title: t('onboarding.initial.5.title', 'Projects Overview'),
        content: t(
          'onboarding.initial.5.content',
          'Manage all your projects from this page. You can track progress, set deadlines, and start new projects here.'
        ),
        path: '/projects',
        data: {
          prev: '/home'
        }
      },
      {
        target: '[data-testid="project-create"]',
        title: t('onboarding.initial.6.title', 'Start a New Project'),
        content: t(
          'onboarding.initial.6.content',
          'Ready to begin a new project? Click here to get started.'
        ),
        path: '/projects',
        data: {
          next: '/projects#offers'
        }
      },
      {
        target: '[data-testid="projects"]',
        placement: 'center',
        title: t('onboarding.initial.7.title', 'Manage Your Offers'),
        content: t(
          'onboarding.initial.7.content',
          'Keep track of all your business offers here. You can view offer details, check their status, and create new ones.'
        ),
        path: '/projects#offers',
        data: {
          prev: '/projects'
        }
      },
      {
        target: '[data-testid="offer-create"]',
        title: t('onboarding.initial.8.title', 'Create a New Offer'),
        content: t(
          'onboarding.initial.8.content',
          'Need to make a new offer for a client? Start the process here.'
        ),
        path: '/projects#offers',
        data: {
          next: '/employees'
        }
      },
      {
        target: '[data-testid="employees"]',
        placement: 'center',
        title: t('onboarding.initial.9.title', 'Employee Management'),
        content: t(
          'onboarding.initial.9.content',
          'Manage your team members here. You can add new employees, organize groups, and view employee details.'
        ),
        path: '/employees',
        data: {
          prev: '/projects#offers'
        }
      },
      {
        target: '[data-testid="employees-view"]',
        title: t('onboarding.initial.10.title', 'Change View'),
        content: t(
          'onboarding.initial.10.content',
          'Switch between table and card views to see your team information in different formats.'
        ),
        path: '/employees'
      },
      {
        target: '[data-testid="employees-import"]',
        title: t('onboarding.initial.11.title', 'Import Employee Data'),
        content: t(
          'onboarding.initial.11.content',
          'Have a list of employees to add? You can upload multiple employee records at once using a CSV file.'
        ),
        path: '/employees'
      },
      {
        target: '[data-testid="employee-create"]',
        title: t('onboarding.initial.12.title', 'Add a New Employee'),
        content: t(
          'onboarding.initial.12.content',
          'Click here to add a new team member to your organization.'
        ),
        path: '/employees'
      },
      {
        target: '[data-testid="employee-groups"]',
        title: t('onboarding.initial.13.title', 'Employee Groups'),
        content: t(
          'onboarding.initial.13.content',
          'Organize your team into groups for easier management and task assignment.'
        ),
        path: '/employees'
      },
      {
        target: '[data-testid="employee-filter"]',
        title: t('onboarding.initial.14.title', 'Filter Employees'),
        content: t(
          'onboarding.initial.14.content',
          'Use these options to find employees based on their current status.'
        ),
        path: '/employees',
        data: {
          next: '/customers'
        }
      },
      {
        target: '[data-testid="customers"]',
        placement: 'center',
        title: t('onboarding.initial.15.title', 'Customer Management'),
        content: t(
          'onboarding.initial.15.content',
          'Keep track of all your customers here. You can manage customer information, create groups, and see related projects.'
        ),
        path: '/customers',
        data: {
          prev: '/employees'
        }
      },
      {
        target: '[data-testid="customers-view"]',
        title: t('onboarding.initial.16.title', 'Change Customer View'),
        content: t(
          'onboarding.initial.16.content',
          'Switch between table and card views to see your customer information in different layouts.'
        ),
        path: '/customers'
      },
      {
        target: '[data-testid="customers-import"]',
        title: t('onboarding.initial.17.title', 'Import Customer List'),
        content: t(
          'onboarding.initial.17.content',
          'Have a list of customers to add? You can upload multiple customer records at once using a CSV file.'
        ),
        path: '/customers'
      },
      {
        target: '[data-testid="customer-create"]',
        title: t('onboarding.initial.18.title', 'Add a New Customer'),
        content: t(
          'onboarding.initial.18.content',
          'Click here to add a new customer to your database.'
        ),
        path: '/customers'
      },
      {
        target: '[data-testid="customer-groups"]',
        title: t('onboarding.initial.19.title', 'Customer Groups'),
        content: t(
          'onboarding.initial.19.content',
          'Organize your customers into groups for better management and targeted communication.'
        ),
        path: '/customers',
        data: {
          next: '/calendar'
        }
      },
      {
        target: '[data-testid="calendar"]',
        placement: 'center',
        title: t('onboarding.initial.20.title', 'Your Calendar'),
        content: t(
          'onboarding.initial.20.content',
          "Here's where you manage your schedule. You can view, add, and organize your events all in one place."
        ),
        path: '/calendar',
        data: {
          prev: '/customers'
        }
      },
      {
        target: '[data-testid="calendar-add-event"]',
        title: t('onboarding.initial.21.title', 'Schedule an Event'),
        content: t(
          'onboarding.initial.21.content',
          "Click here to add a new event to your calendar. It's quick and easy!"
        ),
        path: '/calendar'
      },
      {
        target: '[data-testid="calendar-quick-actions"]',
        title: t('onboarding.initial.22.title', 'Calendar Tools'),
        content: t(
          'onboarding.initial.22.content',
          'Use these buttons to change views, jump to today, or filter your events.'
        ),
        path: '/calendar',
        data: {
          next: '/inventory'
        }
      },
      {
        target: '[data-testid="inventory"]',
        placement: 'center',
        title: t('onboarding.initial.23.title', 'Inventory Management'),
        content: t(
          'onboarding.initial.23.content',
          'Keep track of all your items here. You can organize them into groups, filter them, and add new ones as needed.'
        ),
        path: '/inventory',
        data: {
          prev: '/calendar'
        }
      },
      {
        target: '[data-testid="inventory-view"]',
        title: t('onboarding.initial.24.title', 'Inventory Display Options'),
        content: t(
          'onboarding.initial.24.content',
          'Switch between table and card views to see your inventory in the way that works best for you.'
        ),
        path: '/inventory'
      },
      {
        target: '[data-testid="inventory-import"]',
        title: t('onboarding.initial.25.title', 'Import Inventory Items'),
        content: t(
          'onboarding.initial.25.content',
          'Have a list of items to add? Click here to upload multiple items at once using a CSV file.'
        ),
        path: '/inventory'
      },
      {
        target: '[data-testid="item-create"]',
        title: t('onboarding.initial.26.title', 'Add New Item'),
        content: t(
          'onboarding.initial.26.content',
          'Need to add a single new item to your inventory? Start here.'
        ),
        path: '/inventory'
      },
      {
        target: '[data-testid="inventory-groups"]',
        title: t('onboarding.initial.27.title', 'Inventory Groups'),
        content: t(
          'onboarding.initial.27.content',
          'Organize your inventory into groups to make items easier to find and manage.'
        ),
        path: '/inventory'
      },
      {
        target: '[data-testid="inventory-filter"]',
        title: t('onboarding.initial.28.title', 'Find Specific Items'),
        content: t(
          'onboarding.initial.28.content',
          'Use these filters to quickly find items by their status or category.'
        ),
        path: '/inventory',
        data: {
          next: '/manage-workspace'
        }
      },
      {
        target: '[data-testid="workspace"]',
        title: t('onboarding.initial.29.title', 'Workspace Settings'),
        content: t(
          'onboarding.initial.29.content',
          'Manage your workspace settings, team members, and company information here.'
        ),
        path: '/manage-workspace',
        data: {
          prev: '/inventory',
          next: '/manage-workspace#referrals'
        }
      },
      {
        target: '[data-testid="referrals"]',
        placement: 'center',
        title: t('onboarding.initial.30.title', 'Referral Program'),
        content: t(
          'onboarding.initial.30.content',
          'Join our referral program to earn rewards as you help others discover our service.'
        ),
        path: '/manage-workspace#referrals',
        data: {
          prev: '/manage-workspace'
        }
      },
      {
        target: '[data-testid="referral-progress"]',
        title: t('onboarding.initial.31.title', 'Your Referral Status'),
        content: t(
          'onboarding.initial.31.content',
          "See how many referrals you've made and what rewards you've earned or are close to earning."
        ),
        path: '/manage-workspace#referrals'
      },
      {
        target: '[data-testid="partner-tiers"]',
        title: t('onboarding.initial.32.title', 'Reward Tiers'),
        content: t(
          'onboarding.initial.32.content',
          'Check out the different levels of rewards you can earn through our referral program.'
        ),
        path: '/manage-workspace#referrals'
      },
      {
        target: '[data-testid="referral-link"]',
        title: t('onboarding.initial.33.title', 'Your Unique Referral Link'),
        content: t(
          'onboarding.initial.33.content',
          'Share this link with others to start earning rewards when they sign up.'
        ),
        path: '/manage-workspace#referrals',
        data: {
          next: '/manage-workspace#labels'
        }
      },
      {
        target: '[data-testid="labels"]',
        placement: 'center',
        title: t('onboarding.initial.34.title', 'Custom Labels'),
        content: t(
          'onboarding.initial.34.content',
          'Create and manage labels to organize your calendar events and projects more effectively.'
        ),
        path: '/manage-workspace#labels',
        data: {
          prev: '/manage-workspace#referrals',
          next: '/manage-workspace#auditLog'
        }
      },
      {
        target: '[data-testid="auditLog"]',
        title: t('onboarding.initial.35.title', 'Activity History'),
        content: t(
          'onboarding.initial.35.content',
          "See a record of all actions taken in your workspace. It's a great way to keep track of changes and stay informed."
        ),
        path: '/manage-workspace#auditLog',
        data: {
          prev: '/manage-workspace#labels',
          next: '/account-settings#notifications'
        }
      },
      {
        target: '[data-testid="account-notifications"]',
        placement: 'center',
        title: t('onboarding.initial.36.title', 'Set Up Your Notifications'),
        content: t(
          'onboarding.initial.36.content',
          'Choose how and when you want to be notified about activities in your workspace.'
        ),
        path: '/account-settings#notifications',
        data: {
          prev: '/manage-workspace#auditLog'
        }
      },
      {
        target: '[data-testid="account-notifications-groups"]',
        title: t('onboarding.initial.37.title', 'Notification Categories'),
        content: t(
          'onboarding.initial.37.content',
          'Pick which parts of the app you want to receive updates about, like projects, employees, or inventory.'
        ),
        path: '/account-settings#notifications'
      },
      {
        target: '[data-testid="account-notifications-toggle"]',
        title: t('onboarding.initial.38.title', 'Customize Your Alerts'),
        content: t(
          'onboarding.initial.38.content',
          'For each category, you can choose to get notifications by email, push notifications, or both.'
        ),
        path: '/account-settings#notifications',
        data: {
          next: '/account-settings#integrations'
        }
      },
      {
        target: '[data-testid="integrations"]',
        placement: 'center',
        title: t('onboarding.initial.39.title', 'Connect Your Tools'),
        content: t(
          'onboarding.initial.39.content',
          'Link your favorite apps and services to make your workflow smoother. You can connect calendars and file storage services here.'
        ),
        path: '/account-settings#integrations',
        data: {
          prev: '/account-settings#notifications'
        }
      }
    ]
  } else if (type === 'PROJECT') {
    return [
      {
        target: '[data-testid="project"]',
        placement: 'center',
        title: t('onboarding.project.1.title', 'Project Overview'),
        content: t(
          'onboarding.project.1.content',
          'Welcome to your project page. Here you can manage all aspects of your project in one place.'
        ),
        disableBeacon: true,
        path: '/projects/:id'
      },
      {
        target: '[data-testid="project-overview"]',
        title: t('onboarding.project.2.title', 'Project Details'),
        content: t(
          'onboarding.project.2.content',
          'In this section, you can edit your project description, update its status, and set important deadlines.'
        ),
        path: '/projects/:id'
      },
      {
        target: '[data-testid="project-contacts"]',
        title: t('onboarding.project.3.title', 'Project Contacts'),
        content: t(
          'onboarding.project.3.content',
          'Keep track of all people involved in your project. Add new contacts or view details of existing ones here.'
        ),
        path: '/projects/:id'
      },
      {
        target: '[data-testid="project-members"]',
        title: t('onboarding.project.4.title', 'Team Members'),
        content: t(
          'onboarding.project.4.content',
          'Manage your project team here. Add new members, assign roles, and view member details easily.'
        ),
        path: '/projects/:id'
      },
      {
        target: '[data-testid="project-files"]',
        title: t('onboarding.project.5.title', 'Project Files'),
        content: t(
          'onboarding.project.5.content',
          'Upload, organize, and share all your project-related files in this section. Create folders to keep everything tidy.'
        ),
        path: '/projects/:id',
        data: {
          next: '/projects/:id#tasksBoard'
        }
      },
      {
        target: '[data-testid="tasks-board"]',
        placement: 'center',
        title: t('onboarding.project.6.title', 'Tasks Board'),
        content: t(
          'onboarding.project.6.content',
          `This is your project's task management center. Here you can create, assign, and track all project tasks.`
        ),
        path: '/projects/:id#tasksBoard',
        data: {
          prev: '/projects/:id'
        }
      },
      {
        target: '[data-testid="create-new-task"]',
        title: t('onboarding.project.7.title', 'Create New Task'),
        content: t(
          'onboarding.project.7.content',
          'Click here to add a new task to your project. Define details, set deadlines, and assign team members.'
        ),
        path: '/projects/:id#tasksBoard'
      },
      {
        target: '[data-testid="tasks-board-filter"]',
        title: t('onboarding.project.8.title', 'Filter Tasks'),
        content: t(
          'onboarding.project.8.content',
          `Use these options to filter tasks by assignee, priority, or labels. It helps you focus on what's important.`
        ),
        path: '/projects/:id#tasksBoard'
      },
      {
        target: '[data-testid="tasks-board-view"]',
        title: t('onboarding.project.9.title', 'Change Task View'),
        content: t(
          'onboarding.project.9.content',
          'Switch between different views to see your tasks in a way that works best for you and your team.'
        ),
        path: '/projects/:id#tasksBoard'
      },
      {
        target: '[data-testid="tasks"]',
        title: t('onboarding.project.10.title', 'Task Columns'),
        content: t(
          'onboarding.project.10.content',
          'Tasks are organized into columns based on their status. Drag and drop tasks between columns as they progress.'
        ),
        path: '/projects/:id#tasksBoard',
        data: {
          next: '/projects/:id#timeTracking'
        }
      },
      {
        target: '[data-testid="time-tracking"]',
        placement: 'center',
        title: t('onboarding.project.11.title', 'Time Tracking'),
        content: t(
          'onboarding.project.11.content',
          'Monitor the time spent on your project here. Log hours, view team productivity, and track project progress.'
        ),
        path: '/projects/:id#timeTracking',
        data: {
          prev: '/projects/:id#tasksBoard'
        }
      },
      {
        target: '[data-testid="log-time"]',
        title: t('onboarding.project.12.title', 'Log Time'),
        content: t(
          'onboarding.project.12.content',
          `Click here to record the time you've spent working on the project. Regular logging helps track progress accurately.`
        ),
        path: '/projects/:id#timeTracking'
      },
      {
        target: '[data-testid="export-time-entries"]',
        title: t('onboarding.project.13.title', 'Export Time Data'),
        content: t(
          'onboarding.project.13.content',
          'Need a report of all time entries? Use this feature to export the data to a CSV file for further analysis.'
        ),
        path: '/projects/:id#timeTracking'
      },
      {
        target: '[data-testid="time-analytics"]',
        title: 'Time Analytics',
        content:
          'Get insights into how time is being spent on your project. This data can help in future planning and resource allocation.',
        path: '/projects/:id#timeTracking'
      },
      {
        target: '[data-testid="time-filter"]',
        title: t('onboarding.project.14.title', 'Filter Time Entries'),
        content: t(
          'onboarding.project.14.content',
          'Use these filters to view time entries for specific team members or date ranges.'
        ),

        path: '/projects/:id#timeTracking',
        data: {
          next: '/projects/:id#expenses'
        }
      },
      {
        target: '[data-testid="expenses"]',
        placement: 'center',
        title: t('onboarding.project.15.title', 'Project Expenses'),
        content: t(
          'onboarding.project.15.content',
          'Keep track of all project-related expenses here. Monitor your budget and spending in real-time.'
        ),
        path: '/projects/:id#expenses',
        data: {
          prev: '/projects/:id#timeTracking'
        }
      },
      {
        target: '[data-testid="add-manage-expenses"]',
        title: t('onboarding.project.16.title', 'Manage Expenses'),
        content: t(
          'onboarding.project.16.content',
          'Add new expenses or adjust your project budget from this section. Keeping this up-to-date ensures accurate financial tracking.'
        ),
        path: '/projects/:id#expenses'
      },
      {
        target: '[data-testid="expenses-analytics"]',
        title: t('onboarding.project.17.title', 'Expense Analytics'),
        content: t(
          'onboarding.project.17.content',
          'View detailed breakdowns of your project expenses. This can help identify areas where costs can be optimized.'
        ),
        path: '/projects/:id#expenses'
      },
      {
        target: '[data-testid="expenses-filter"]',
        title: t('onboarding.project.18.title', 'Filter Expenses'),
        content: t(
          'onboarding.project.18.content',
          'Use these options to filter expenses by labels, amount, or date range. It helps in generating specific expense reports.'
        ),
        path: '/projects/:id#expenses'
      }
    ]
  }

  return []
}
