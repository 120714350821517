import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

export const CustomLoader = ({ size = 'md', className = '' }) => {
  const sizeClasses = {
    xs: 'w-4 h-4',
    sm: 'w-8 h-8',
    md: 'w-12 h-12',
    lg: 'w-16 h-16',
    xl: 'w-20 h-20',
    '2xl': 'w-24 h-24'
  }

  return (
    <div className={`${className}`}>
      <div className={sizeClasses[size]}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          className="w-full h-full"
        >
          <style>
            {`
              @keyframes draw {
                0% { stroke-dashoffset: 400; }
                100% { stroke-dashoffset: 0; }
              }
              @keyframes pulse {
                0%, 100% { transform: scale(1); }
                50% { transform: scale(1.02); }
              }
              .animate-draw {
                animation: draw 2.5s ease-in-out infinite;
              }
              .animate-pulse {
                animation: pulse 2s ease-in-out infinite;
              }
            `}
          </style>
          <g className="animate-pulse">
            <path
              d="M50 3C24.5 3 3 24.5 3 50s21.5 47 47 47 47-21.5 47-47S75.5 3 50 3zm25.7 70.3h-5.8c-9.9 0-16.5.8-19.9-6.1l-10.3-22.5c-2.3-4.7-2.4-5.1-6.9-5.1l-5.5 33.6H16.1l8.3-45.9h5.8c9.9 0 16.3-.7 19.6 5.6l10.1 23.1c2.1 4.5 2.4 4.8 6.8 4.8l6.2-33.5h11.2l-8.3 45.9z"
              className="fill-none stroke-indigo-600 stroke-[3] animate-draw"
              style={{
                strokeDasharray: 400,
                strokeLinecap: 'round',
                strokeLinejoin: 'round'
              }}
            />
          </g>
        </svg>
      </div>
    </div>
  )
}

const CustomLoading = ({ size = 'md', className = '', showText = true }) => {
  const { t } = useTranslation('general')

  const textSizeClasses = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl'
  }

  const dotVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  }

  return (
    <div className={`inline-flex flex-col items-center ${className}`}>
      <CustomLoader size={size} className={className} />

      {showText && (
        <div
          className={`ml-3 mt-2 text-indigo-600 font-semibold flex items-center ${textSizeClasses[size]}`}
        >
          {t('others.loading', 'Loading')}
          <motion.span
            variants={dotVariants}
            initial="hidden"
            animate="visible"
            transition={{
              repeat: Infinity,
              duration: 0.5,
              repeatType: 'reverse'
            }}
          >
            .
          </motion.span>
          <motion.span
            variants={dotVariants}
            initial="hidden"
            animate="visible"
            transition={{
              repeat: Infinity,
              duration: 0.5,
              repeatType: 'reverse',
              delay: 0.2
            }}
          >
            .
          </motion.span>
          <motion.span
            variants={dotVariants}
            initial="hidden"
            animate="visible"
            transition={{
              repeat: Infinity,
              duration: 0.5,
              repeatType: 'reverse',
              delay: 0.4
            }}
          >
            .
          </motion.span>
        </div>
      )}
    </div>
  )
}

export default CustomLoading
