import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { useConfig } from '../ConfigProvider'
import { useForm } from '../Form/context'
import { useInputGroup } from '../InputGroup/context'
import { HiCheck, HiChevronDown, HiX } from 'react-icons/hi'
import Spinner from '../Spinner'
import { CONTROL_SIZES, SIZES } from '../utils/constant'

const DefaultOption = ({
  innerProps,
  label,
  selectProps,
  isSelected,
  isDisabled,
  isFocused
}) => {
  const { themeColor } = selectProps
  return (
    <div
      className={classNames(
        'select-option',
        isSelected && 'selected',
        isDisabled && 'disabled',
        isFocused && 'focused'
      )}
      {...innerProps}
    >
      <span className="ml-2">{label}</span>
      {isSelected && <HiCheck className={`text-${themeColor} text-xl`} />}
    </div>
  )
}

const DefaultDropdownIndicator = () => {
  return (
    <div className="select-dropdown-indicator">
      <HiChevronDown />
    </div>
  )
}

const DefaultClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps }
  } = props

  return (
    <div {...restInnerProps} ref={ref}>
      <div className="select-clear-indicator">
        <HiX />
      </div>
    </div>
  )
}

const DefaultLoadingIndicator = ({ selectProps }) => {
  const { themeColor } = selectProps

  return <Spinner className={`select-loading-indicatior text-${themeColor}`} />
}

const Select = React.forwardRef((props, ref) => {
  const {
    size,
    style,
    className,
    form,
    field,
    components,
    onOpen,
    onClose,
    componentAs: Component = ReactSelect,
    ...rest
  } = props

  const { themeColor, controlSize, primaryColorLevel } = useConfig()
  const formControlSize = useForm()?.size
  const inputGroupSize = useInputGroup()?.size

  const selectSize = size || inputGroupSize || formControlSize || controlSize

  const colors = {
    indigo: {
      50: '#f2f5fc',
      100: '#e0e7ff',
      600: '#455bcb'
    },
    gray: {
      200: '#d1d1d1',
      300: '#b0b0b0',
      600: '#5d5d5d',
      700: '#4f4f4f'
    },
    red: {
      500: '#e63946'
    }
  }

  const heights = {
    7: 1.75,
    9: 2.25,
    11: 2.75,
    14: 3.5
  }

  let isInvalid = false

  if (!isEmpty(form)) {
    const { touched, errors } = form

    const touchedField = get(touched, field.name)
    const errorField = get(errors, field.name)

    isInvalid = touchedField && errorField
  }

  const getBoxShadow = (state) => {
    const shadowBase = '0 0 0 1px '

    if (isInvalid) {
      return shadowBase + colors.red['500']
    }

    if (state.isFocused) {
      return shadowBase + colors.primary
    }

    return 'none'
  }

  const styles = {
    control: (provided, state) => {
      return {
        ...provided,
        height: `${heights[CONTROL_SIZES[selectSize]]}rem`,
        minHeight: `${heights[CONTROL_SIZES[selectSize]]}rem`,
        '&:hover': {
          boxShadow: getBoxShadow(state),
          cursor: 'pointer'
        },
        boxShadow: getBoxShadow(state),
        borderRadius: '0.375rem',
        borderWidth: state.isFocused ? '2px' : '1px',
        ...(isInvalid ? { borderColor: colors.red['500'] } : {})
      }
    },
    input: (css) => {
      return {
        ...css,
        input: {
          outline: 'none',
          outlineOffset: 0,
          boxShadow: 'none !important'
        }
      }
    },
    menu: (provided) => ({ ...provided, zIndex: 50 }),
    ...style
  }

  const selectClass = classNames('select', `select-${selectSize}`, className)

  return (
    <Component
      className={selectClass}
      classNamePrefix={'select'}
      ref={ref}
      styles={styles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral20: colors.gray['200'],
          neutral30: colors.gray['200'],
          neutral80: colors.gray['700'],
          neutral10: colors.gray['200'],
          primary25: colors[themeColor]['50'],
          primary50: colors[themeColor]['100'],
          primary: colors[themeColor][primaryColorLevel]
        }
      })}
      themeColor={`${themeColor}-${primaryColorLevel}`}
      components={{
        IndicatorSeparator: () => null,
        Option: DefaultOption,
        LoadingIndicator: DefaultLoadingIndicator,
        DropdownIndicator: DefaultDropdownIndicator,
        ClearIndicator: DefaultClearIndicator,
        ...components
      }}
      onMenuOpen={() => {
        onOpen?.()
      }}
      onMenuClose={() => {
        onClose?.()
      }}
      {...field}
      {...rest}
    />
  )
})

Select.displayName = 'Select'

Select.propTypes = {
  size: PropTypes.oneOf([SIZES.LG, SIZES.MD, SIZES.SM, SIZES.XS]),
  componentAs: PropTypes.elementType
}

export default Select
