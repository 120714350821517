const appConfig = {
  apiPrefix: import.meta.env.VITE_API_PREFIX || '/api/v1',
  socketPrefix: import.meta.env.VITE_SOCKET_PREFIX || '/api/v1',
  authenticatedEntryPath: '/home',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en'
}

export default appConfig
