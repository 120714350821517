import ApiService from './ApiService'

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: 'auth/login',
    method: 'post',
    data
  })
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: 'auth/register',
    method: 'post',
    data
  })
}

export async function apiResendVerification(data) {
  return ApiService.fetchData({
    url: 'auth/resend-verification',
    method: 'post',
    data
  })
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: '/sign-out',
    method: 'post',
    data
  })
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: 'auth/forgot-password',
    method: 'post',
    data
  })
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: 'auth/reset-password',
    method: 'post',
    data
  })
}

export async function apiGenerateSecret() {
  return ApiService.fetchData({
    url: 'auth/generate',
    method: 'get'
  })
}

export async function apiEnableTwoAuth(data) {
  return ApiService.fetchData({
    url: 'auth/enable',
    method: 'post',
    data
  })
}

export async function apiDisableTwoAuth(data) {
  return ApiService.fetchData({
    url: 'auth/disable',
    method: 'post',
    data
  })
}

export async function apiRequestVerification(data) {
  return ApiService.fetchData({
    url: 'auth/request-verification',
    method: 'post',
    data
  })
}
