import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  data: {
    id: '',
    name: '',
    description: '',
    role: '',
    members: []
  },
  loaded: false
}

export const workspaceSlice = createSlice({
  name: 'workspace/workspace',
  initialState,
  reducers: {
    setWorkspace: (state, action) => {
      state.data = { ...state.data, ...action.payload }
      state.loaded = true
      return state
    },
    removeMember: (state, action) => {
      state.data.members = state.data.members.filter(
        (member) => member.user.id !== action.payload
      )
    },
    updateMember: (state, action) => {
      const { id } = action.payload

      delete action.payload.id

      state.data.members = state.data.members.map((member) =>
        member.user.id === id ? { ...member, ...action.payload } : member
      )
    }
  }
})

export const { setWorkspace, removeMember, updateMember } =
  workspaceSlice.actions

export default workspaceSlice.reducer
