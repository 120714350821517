import React from 'react'
import authRoute from './authRoute'

export const publicRoutes = [
  ...authRoute,
  {
    title: 'Offer View',
    key: 'offer-view',
    path: '/offers/:idOrToken',
    component: React.lazy(
      () => import('@/views/projects/components/offers/OfferView')
    ),
    authority: []
  },
  {
    key: 'pages.accessDenied',
    path: '/access-denied',
    component: React.lazy(() => import('@/views/accessDenied/index')),
    authority: ['USER', 'ADMIN']
  },
  {
    key: 'referral',
    path: '/r/:code',
    component: React.lazy(() => import('@/views/referral/index')),
    authority: []
  }
]

export const protectedRoutes = [
  {
    title: 'Home',
    key: 'home',
    path: '/home',
    component: React.lazy(() => import('@/views/home/index')),
    authority: [
      'WORKSPACE.CLIENT',
      'WORKSPACE.EMPLOYEE',
      'WORKSPACE.MANAGER',
      'WORKSPACE.OWNER'
    ],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Setup Workspace',
    key: 'setup',
    path: '/setup',
    component: React.lazy(() => import('@/views/setup/index')),
    authority: [],
    meta: {
      requiresWorkspace: false
    }
  },
  {
    title: 'Create Workspace',
    key: 'create-workspace',
    path: '/create-workspace',
    component: React.lazy(() => import('@/views/createWorkspace/index')),
    authority: [],
    meta: {
      requiresWorkspace: false
    }
  },
  {
    title: 'Manage Workspace',
    key: 'manage-workspace',
    path: '/manage-workspace',
    component: React.lazy(() => import('@/views/manageWorkspace/index')),
    authority: ['WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Workspace Invitation',
    key: 'workspace-invite',
    path: '/workspace-invite/:token',
    component: React.lazy(() => import('@/views/workspaceInvite/index')),
    meta: {
      requiresWorkspace: false
    }
  },
  {
    title: 'Projects',
    key: 'projects',
    path: '/projects',
    component: React.lazy(() => import('@/views/projects/index')),
    authority: [
      'WORKSPACE.CLIENT',
      'WORKSPACE.EMPLOYEE',
      'WORKSPACE.MANAGER',
      'WORKSPACE.OWNER'
    ],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Project Detail',
    key: 'project-detail',
    path: '/projects/:id',
    component: React.lazy(() => import('@/views/projectDetail/index')),
    authority: [
      'WORKSPACE.CLIENT',
      'WORKSPACE.EMPLOYEE',
      'WORKSPACE.MANAGER',
      'WORKSPACE.OWNER'
    ],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'New Offer',
    key: 'offers',
    path: '/projects/offers',
    component: React.lazy(
      () => import('@/views/projects/components/offers/OfferForm')
    ),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Edit Offer',
    key: 'offers-edit',
    path: '/projects/offers/:id',
    component: React.lazy(
      () => import('@/views/projects/components/offers/OfferForm')
    ),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Inventory',
    key: 'inventory',
    path: '/inventory',
    component: React.lazy(() => import('@/views/inventory/index')),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Add Inventory Item',
    key: 'inventory-add',
    path: '/inventory/add',
    component: React.lazy(
      () => import('@/views/inventory/components/ItemForm')
    ),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'View Inventory Item',
    key: 'inventory-view',
    path: '/inventory/:id',
    component: React.lazy(
      () => import('@/views/inventory/components/ItemForm')
    ),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Employees',
    key: 'employees',
    path: '/employees',
    component: React.lazy(() => import('@/views/employees/index')),
    authority: ['WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Add Employee',
    key: 'employees-add',
    path: '/employees/add',
    component: React.lazy(
      () => import('@/views/employees/components/EmployeeForm')
    ),
    authority: ['WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'View Employee',
    key: 'employees-view',
    path: '/employees/:id',
    component: React.lazy(
      () => import('@/views/employees/components/EmployeeForm')
    ),
    authority: ['WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Customers',
    key: 'customers',
    path: '/customers',
    component: React.lazy(() => import('@/views/customers/index')),
    authority: ['WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Account Settings',
    key: 'account-settings',
    path: '/account-settings',
    component: React.lazy(() => import('@/views/accountSettings/index')),
    authority: [],
    meta: {
      requiresWorkspace: false
    }
  },
  {
    title: 'Calendar',
    key: 'calendar',
    path: '/calendar',
    component: React.lazy(() => import('@/views/calendar/index')),
    authority: [
      'WORKSPACE.CLIENT',
      'WORKSPACE.EMPLOYEE',
      'WORKSPACE.MANAGER',
      'WORKSPACE.OWNER'
    ],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Chat',
    key: 'chat',
    path: '/chat',
    component: React.lazy(() => import('@/views/chat/index')),
    authority: [
      'WORKSPACE.CLIENT',
      'WORKSPACE.EMPLOYEE',
      'WORKSPACE.MANAGER',
      'WORKSPACE.OWNER'
    ],
    meta: {
      pageContainerType: 'gutterless',
      footer: false,
      requiresWorkspace: true
    }
  },
  {
    title: 'Chat Room',
    key: 'chat-room',
    path: '/chat/:type/:id',
    component: React.lazy(() => import('@/views/chat/index')),
    authority: [
      'WORKSPACE.CLIENT',
      'WORKSPACE.EMPLOYEE',
      'WORKSPACE.MANAGER',
      'WORKSPACE.OWNER'
    ],
    meta: {
      pageContainerType: 'gutterless',
      footer: false,
      requiresWorkspace: true
    }
  },
  {
    title: 'Storage',
    key: 'storage',
    path: '/storage/:provider',
    component: React.lazy(() => import('@/views/storage/index')),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Storage',
    key: 'storage',
    path: '/storage/:provider/:id',
    component: React.lazy(() => import('@/views/storage/index')),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  },
  {
    title: 'Suggestions',
    key: 'suggestions',
    path: '/suggestions',
    component: React.lazy(() => import('@/views/suggestions/index')),
    authority: ['WORKSPACE.EMPLOYEE', 'WORKSPACE.MANAGER', 'WORKSPACE.OWNER'],
    meta: {
      requiresWorkspace: true
    }
  }
]
