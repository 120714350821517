import ApiService from './ApiService'

export async function apiGetUserWorkspaces() {
  return ApiService.fetchData({
    url: 'workspaces',
    method: 'get'
  })
}

export async function apiCreateWorkspace(data) {
  return ApiService.fetchData({
    url: 'workspaces',
    method: 'post',
    data
  })
}

export async function apiGetWorkspace(id) {
  return ApiService.fetchData({
    url: `workspaces/${id}`,
    method: 'get'
  })
}

export async function apiUpdateWorkspace(data) {
  return ApiService.fetchData({
    url: `workspaces`,
    method: 'patch',
    data
  })
}

export async function apiInviteMembers(members) {
  return ApiService.fetchData({
    url: `workspaces/invite`,
    method: 'post',
    data: members
  })
}

export async function apiGetInvite(token) {
  return ApiService.fetchData({
    url: `workspaces/invite/${token}`,
    method: 'get'
  })
}

export async function apiAcceptInvite(token) {
  return ApiService.fetchData({
    url: `workspaces/invite/${token}`,
    method: 'post'
  })
}

export async function apiRejectInvite(token) {
  return ApiService.fetchData({
    url: `workspaces/invite/${token}`,
    method: 'delete'
  })
}

export async function apiGetLabels(type) {
  let url = 'workspaces/labels'
  if (type) {
    url += `?type=${type}`
  }

  return ApiService.fetchData({
    url,
    method: 'get'
  })
}

export async function apiCreateLabel(data) {
  return ApiService.fetchData({
    url: `workspaces/labels`,
    method: 'post',
    data
  })
}

export async function apiUpdateLabel(labelId, data) {
  return ApiService.fetchData({
    url: `workspaces/labels/${labelId}`,
    method: 'patch',
    data
  })
}

export async function apiDeleteLabel(labelId) {
  return ApiService.fetchData({
    url: `workspaces/labels/${labelId}`,
    method: 'delete'
  })
}

export async function apiUpdateMember(id, role) {
  return ApiService.fetchData({
    url: `workspaces/members/${id}`,
    method: 'patch',
    data: { role }
  })
}

export async function apiRemoveMember(id) {
  return ApiService.fetchData({
    url: `workspaces/members/${id}`,
    method: 'delete'
  })
}

export async function apiRestoreMember(id) {
  return ApiService.fetchData({
    url: `workspaces/members/${id}/restore`,
    method: 'post'
  })
}
