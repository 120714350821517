import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'manageWorkspace/data',
  initialState: {
    loading: true,
    billingData: {
      subscription: {},
      cards: [],
      invoices: [],
      info: {}
    },
    logs: [],
    labels: []
  },
  reducers: {
    setBillingData: (state, action) => {
      state.billingData = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setLogs: (state, action) => {
      state.logs = action.payload
    },
    appendLogs: (state, action) => {
      state.logs = [...state.logs, ...action.payload]
    },
    setLabels: (state, action) => {
      state.labels = action.payload
    }
  }
})

export const { setBillingData, setLoading, setLogs, appendLogs, setLabels } =
  dataSlice.actions

export default dataSlice.reducer
