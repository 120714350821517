import React from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import CloseButton from '../CloseButton'
import useWindowSize from '../hooks/useWindowSize'
import './styles.css'

const Dialog = (props) => {
  const currentSize = useWindowSize()

  const {
    children,
    className,
    closable = true,
    width = 520,
    height,
    style,
    isOpen,
    onClose,
    bodyOpenClassName,
    portalClassName,
    overlayClassName,
    contentClassName,
    closeTimeoutMS = 200,
    ...rest
  } = props

  const onCloseClick = (e) => {
    onClose(e)
  }

  const renderCloseButton = (
    <CloseButton onClick={onCloseClick} className="right-6" absolute />
  )

  const contentStyle = {
    content: {},
    ...style
  }

  if (width !== undefined) {
    contentStyle.content.width = width

    if (currentSize.width <= 640) {
      contentStyle.content.width = 'auto'
    }
  }
  if (height !== undefined) {
    contentStyle.content.height = height
  }

  const defaultDialogContentClass = 'p-6'

  const dialogClass = classNames(defaultDialogContentClass, contentClassName)

  return (
    <Modal
      className={classNames(
        'absolute bottom-0 lg:top-16 lg:right-10 lg:bottom-10 lg:left-1/2 border border-[#cccccc] bg-white overflow-auto lg:overflow-visible rounded-lg outline-none p-0 lg:-translate-x-1/2 lg:max-w-[calc(100%-24px)] min-h-fit max-h-[95vh] lg:max-h-screen',
        className
      )}
      overlayClassName={classNames(
        'fixed inset-0 bg-black bg-opacity-60 z-50 overflow-hidden lg:overflow-auto',
        overlayClassName
      )}
      portalClassName={
        portalClassName ? classNames(portalClassName) : undefined
      }
      bodyOpenClassName={bodyOpenClassName}
      ariaHideApp={false}
      isOpen={isOpen}
      style={{ ...contentStyle }}
      closeTimeoutMS={closeTimeoutMS}
      {...rest}
    >
      <div className={dialogClass}>
        {closable && renderCloseButton}
        {children}
      </div>
    </Modal>
  )
}

Dialog.propTypes = {
  closable: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  portalClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  closeTimeoutMS: PropTypes.number,
  bodyOpenClassName: PropTypes.string
}

export default Dialog
