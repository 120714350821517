export const themeConfig = {
  themeColor: 'indigo',
  direction: 'ltr',
  mode: 'light',
  primaryColorLevel: 600,
  cardBordered: true,
  panelExpand: false,
  controlSize: 'md',
  navMode: 'themed',
  layout: {
    type: 'classic',
    sideNavCollapse: false
  }
}
