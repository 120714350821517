import React from 'react'

const FormDescription = ({ title, desc, descIsHtml, action, ...rest }) => {
  return (
    <div {...rest}>
      <div className="flex flex-row justify-between items-center gap-2">
        <h5>{title}</h5>
        {action && <div>{action}</div>}
      </div>

      <p>
        {descIsHtml ? <div dangerouslySetInnerHTML={{ __html: desc }} /> : desc}
      </p>
    </div>
  )
}

export default FormDescription
