import { createSlice } from '@reduxjs/toolkit'

const dataSlice = createSlice({
  name: 'projectList/data',
  initialState: {
    loading: false,
    projectList: []
  },
  reducers: {
    addProject: (state, action) => {
      state.projectList = [action.payload, ...state.projectList]
    },
    setProjects: (state, action) => {
      state.projectList = action.payload
    }
  }
})

export const { addProject, setProjects } = dataSlice.actions

export default dataSlice.reducer
